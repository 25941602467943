// COMMON STYLES FOR BONUS DETAIL
.bonus-detail {
  .title {
    font-size: 19px;
    font-weight: 700;
    padding: 30px;
    margin: 0;
    color: var(--ion-color-dark);
  }

  .subtitle {
    font-weight: 900;
    font-size: 15px;
    color: var(--ion-color-dark);
  }

  .paragraph {
    padding: 0 20px;
    color: var(--ion-color-medium-tint);
  }

  ul {
    float: left;
    list-style-type: none;
    margin: 0;
    padding: 20px 0;
    li {
      margin-bottom: 0.7rem;
      padding-left: 25px;
      position: relative;
      &::before {
        content: '';
        background: #f40034;
        clear: both;
        font-weight: 700;
        width: 7px;
        height: 7px;
        position: absolute;
        top: 7px;
        left: 0px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
      }
    }
  }

  ion-list {
    background: none;
    ion-item {
      --background: #fff;
      font-size: 1em;
      .icon {
        font-size: 1.3em;
        //line-height: 1em;

        // color: var(--ion-color-primary-contrast-freebet);
      }
    }
  }
}
