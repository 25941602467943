/* FLEX UTILITY */
.flex {
  display: flex;
}

.relative {
  position: relative;
}

.pointer-events-none {
  pointer-events: none;
}
