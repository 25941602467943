.small-modal {
  --min-width: 250px;
  --width: 90%;
  --max-width: 300px;
  --height: auto;
  --max-height: 70%;
  color: var(--ion-text-color, #000);
  .ion-page {
    position: relative;
    contain: content;
    max-height: 70vh;
  }
}
