* {
  -webkit-font-smoothing: antialiased;
}
body,
* {
  -webkit-tap-highlight-color: transparent;
}

html {
  line-height: normal;
}

button:focus {
  outline: none;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0px !important;
  margin: 0px !important;
}

body.glightbox-open {
  height: 100%;
}