$sportColorSet: '48' rgb(77, 148, 255) rgba(77, 148, 255, 0.15)
    rgba(77, 148, 255, 0.08) rgb(0, 82, 204),
  '49' rgb(178, 71, 0) rgba(178, 71, 0, 0.15) rgba(178, 71, 0, 0.08)
    rgb(204, 82, 0),
  '50' rgb(204, 0, 0) rgba(204, 0, 0, 0.15) rgba(204, 0, 0, 0.08) rgb(178, 0, 0),
  '51' rgb(204, 82, 0) rgba(204, 82, 0, 0.15) rgba(204, 82, 0, 0.08)
    rgb(204, 82, 0),
  '52' rgb(255, 178, 128) rgba(255, 178, 128, 0.15) rgba(255, 178, 128, 0.08)
    rgb(204, 82, 0),
  '53' rgb(0, 82, 204) rgba(0, 82, 204, 0.15) rgba(0, 82, 204, 0.08)
    rgb(0, 82, 204),
  '54' rgb(0, 204, 0) rgba(0, 204, 0, 0.15) rgba(0, 204, 0, 0.08)
    rgb(74, 150, 74),
  '55' rgb(230, 92, 0) rgba(230, 92, 0, 0.15) rgba(230, 92, 0, 0.08)
    rgb(204, 82, 0),
  '56' rgb(90, 90, 90) rgba(90, 90, 90, 0.15) rgba(90, 90, 90, 0.08)
    rgb(90, 90, 90),
  '57' rgb(61, 92, 153) rgba(61, 92, 153, 0.15) rgba(61, 92, 153, 0.08)
    rgb(61, 92, 153),
  '58' rgb(255, 117, 25) rgba(255, 117, 25, 0.15) rgba(255, 117, 25, 0.08)
    rgb(204, 82, 0),
  '60' rgb(166, 77, 77) rgba(166, 77, 77, 0.15) rgba(166, 77, 77, 0.08)
    rgb(141, 25, 25),
  '61' rgb(0, 163, 0) rgba(0, 163, 0, 0.15) rgba(0, 163, 0, 0.08)
    rgb(74, 150, 74),
  '62' rgb(0, 143, 71) rgba(0, 143, 71, 0.15) rgba(0, 143, 71, 0.08)
    rgb(74, 150, 74),
  '63' rgb(255, 102, 0) rgba(255, 102, 0, 0.15) rgba(255, 102, 0, 0.08)
    rgb(204, 82, 0),
  '64' rgb(0, 122, 61) rgba(0, 122, 61, 0.15) rgba(0, 122, 61, 0.08)
    rgb(74, 150, 74),
  '65' rgb(153, 0, 0) rgba(153, 0, 0, 0.15) rgba(153, 0, 0, 0.08) rgb(178, 0, 0),
  '66' rgb(0, 184, 0) rgba(0, 184, 0, 0.15) rgba(0, 184, 0, 0.08)
    rgb(74, 150, 74),
  '67' rgb(115, 0, 0) rgba(115, 0, 0, 0.15) rgba(115, 0, 0, 0.08)
    rgb(141, 25, 25),
  '68' rgb(77, 219, 77) rgba(77, 219, 77, 0.15) rgba(77, 219, 77, 0.08)
    rgb(74, 150, 74),
  '69' rgb(214, 173, 153) rgba(214, 173, 153, 0.15) rgba(214, 173, 153, 0.08)
    rgb(138, 46, 0),
  '70' rgb(138, 46, 0) rgba(138, 46, 0, 0.15) rgba(138, 46, 0, 0.08)
    rgb(138, 46, 0),
  '71' rgb(25, 209, 25) rgba(25, 209, 25, 0.15) rgba(25, 209, 25, 0.08)
    rgb(74, 150, 74),
  '72' rgb(255, 153, 153) rgba(255, 153, 153, 0.15) rgba(255, 153, 153, 0.08)
    rgb(178, 0, 0),
  '73' rgb(204, 153, 128) rgba(204, 153, 128, 0.15) rgba(204, 153, 128, 0.08)
    rgb(138, 46, 0),
  '74' rgb(255, 204, 0) rgba(255, 204, 0, 0.15) rgba(255, 204, 0, 0.08)
    rgb(255, 204, 0),
  '75' rgb(141, 25, 25) rgba(141, 25, 25, 0.15) rgba(141, 25, 25, 0.08)
    rgb(141, 25, 25),
  '76' rgb(51, 133, 255) rgba(51, 133, 255, 0.15) rgba(51, 133, 255, 0.08)
    rgb(0, 82, 204),
  '77' rgb(46, 184, 230) rgba(46, 184, 230, 0.15) rgba(46, 184, 230, 0.08)
    rgb(41, 163, 204),
  '78' rgb(153, 51, 0) rgba(153, 51, 0, 0.15) rgba(153, 51, 0, 0.08)
    rgb(138, 46, 0),
  '79' rgb(255, 230, 128) rgba(255, 230, 128, 0.15) rgba(255, 230, 128, 0.08)
    rgb(255, 204, 0),
  '80' rgb(51, 204, 255) rgba(51, 204, 255, 0.15) rgba(51, 204, 255, 0.08)
    rgb(41, 163, 204),
  '81' rgb(255, 133, 51) rgba(255, 133, 51, 0.15) rgba(255, 133, 51, 0.08)
    rgb(204, 82, 0),
  '82' rgb(204, 255, 51) rgba(204, 255, 51, 0.15) rgba(204, 255, 51, 0.08)
    rgb(204, 255, 51),
  '83' rgb(71, 209, 255) rgba(71, 209, 255, 0.15) rgba(71, 209, 255, 0.08)
    rgb(41, 163, 204),
  '85' rgb(0, 204, 102) rgba(0, 204, 102, 0.15) rgba(0, 204, 102, 0.08)
    rgb(74, 150, 74),
  '86' rgb(255, 219, 77) rgba(255, 219, 77, 0.15) rgba(255, 219, 77, 0.08)
    rgb(255, 204, 0),
  '87' rgb(51, 214, 51) rgba(51, 214, 51, 0.15) rgba(51, 214, 51, 0.08)
    rgb(74, 150, 74),
  '88' rgb(0, 143, 0) rgba(0, 143, 0, 0.15) rgba(0, 143, 0, 0.08)
    rgb(74, 150, 74),
  '89' rgb(255, 194, 153) rgba(255, 194, 153, 0.15) rgba(255, 194, 153, 0.08)
    rgb(204, 82, 0),
  '90' rgb(102, 102, 102) rgba(102, 102, 102, 0.15) rgba(102, 102, 102, 0.08)
    rgb(90, 90, 90),
  '91' rgb(163, 117, 255) rgba(163, 117, 255, 0.15) rgba(163, 117, 255, 0.08)
    rgb(122, 82, 204),
  '92' rgb(128, 128, 128) rgba(128, 128, 128, 0.15) rgba(128, 128, 128, 0.08)
    rgb(90, 90, 90),
  '93' rgb(255, 163, 102) rgba(255, 163, 102, 0.15) rgba(255, 163, 102, 0.08)
    rgb(204, 82, 0),
  '94' rgb(255, 214, 51) rgba(255, 214, 51, 0.15) rgba(255, 214, 51, 0.08)
    rgb(255, 204, 0),
  '95' rgb(153, 153, 153) rgba(153, 153, 153, 0.15) rgba(153, 153, 153, 0.08)
    rgb(90, 90, 90),
  '96' rgb(255, 102, 102) rgba(255, 102, 102, 0.15) rgba(255, 102, 102, 0.08)
    rgb(178, 0, 0),
  '97' rgb(102, 0, 0) rgba(102, 0, 0, 0.15) rgba(102, 0, 0, 0.08)
    rgb(141, 25, 25),
  '98' rgb(0, 153, 153) rgba(0, 153, 153, 0.15) rgba(0, 153, 153, 0.08)
    rgb(0, 122, 122),
  '99' rgb(179, 102, 102) rgba(179, 102, 102, 0.15) rgba(179, 102, 102, 0.08)
    rgb(141, 25, 25),
  '100' rgb(0, 138, 138) rgba(0, 138, 138, 0.15) rgba(0, 138, 138, 0.08)
    rgb(0, 122, 122),
  '101' rgb(230, 184, 0) rgba(230, 184, 0, 0.15) rgba(230, 184, 0, 0.08)
    rgb(255, 204, 0),
  '102' rgb(112, 219, 255) rgba(112, 219, 255, 0.15) rgba(112, 219, 255, 0.08)
    rgb(41, 163, 204),
  '103' rgb(255, 77, 77) rgba(255, 77, 77, 0.15) rgba(255, 77, 77, 0.08)
    rgb(178, 0, 0),
  '104' rgb(92, 214, 255) rgba(92, 214, 255, 0.15) rgba(92, 214, 255, 0.08)
    rgb(41, 163, 204),
  '105' rgb(76, 76, 76) rgba(76, 76, 76, 0.15) rgba(76, 76, 76, 0.08)
    rgb(90, 90, 90),
  '106' rgb(153, 51, 51) rgba(153, 51, 51, 0.15) rgba(153, 51, 51, 0.08)
    rgb(141, 25, 25),
  '107' rgb(194, 133, 102) rgba(194, 133, 102, 0.15) rgba(194, 133, 102, 0.08)
    rgb(138, 46, 0),
  '108' rgb(163, 71, 25) rgba(163, 71, 25, 0.15) rgba(163, 71, 25, 0.08)
    rgb(138, 46, 0),
  '109' rgb(255, 0, 0) rgba(255, 0, 0, 0.15) rgba(255, 0, 0, 0.08)
    rgb(178, 0, 0),
  '110' rgb(255, 51, 51) rgba(255, 51, 51, 0.15) rgba(255, 51, 51, 0.08)
    rgb(178, 0, 0),
  '111' rgb(178, 0, 0) rgba(178, 0, 0, 0.15) rgba(178, 0, 0, 0.08)
    rgb(178, 0, 0),
  '112' rgb(163, 194, 255) rgba(163, 194, 255, 0.15) rgba(163, 194, 255, 0.08)
    rgb(61, 92, 153),
  '113' rgb(0, 184, 92) rgba(0, 184, 92, 0.15) rgba(0, 184, 92, 0.08)
    rgb(74, 150, 74),
  '114' rgb(71, 107, 178) rgba(71, 107, 178, 0.15) rgba(71, 107, 178, 0.08)
    rgb(61, 92, 153),
  '115' rgb(102, 163, 255) rgba(102, 163, 255, 0.15) rgba(102, 163, 255, 0.08)
    rgb(0, 82, 204),
  '116' rgb(0, 122, 122) rgba(0, 122, 122, 0.15) rgba(0, 122, 122, 0.08)
    rgb(0, 122, 122),
  '117' rgb(92, 138, 230) rgba(92, 138, 230, 0.15) rgba(92, 138, 230, 0.08)
    rgb(61, 92, 153),
  '118' rgb(0, 102, 255) rgba(0, 102, 255, 0.15) rgba(0, 102, 255, 0.08)
    rgb(0, 82, 204),
  '119' rgb(0, 92, 230) rgba(0, 92, 230, 0.15) rgba(0, 92, 230, 0.08)
    rgb(0, 82, 204),
  '120' rgb(133, 173, 255) rgba(133, 173, 255, 0.15) rgba(133, 173, 255, 0.08)
    rgb(61, 92, 153),
  '121' rgb(25, 117, 255) rgba(25, 117, 255, 0.15) rgba(25, 117, 255, 0.08)
    rgb(0, 82, 204),
  '122' rgb(128, 178, 255) rgba(128, 178, 255, 0.15) rgba(128, 178, 255, 0.08)
    rgb(0, 82, 204),
  '123' rgb(153, 194, 255) rgba(153, 194, 255, 0.15) rgba(153, 194, 255, 0.08)
    rgb(0, 82, 204),
  '124' rgb(117, 163, 255) rgba(117, 163, 255, 0.15) rgba(117, 163, 255, 0.08)
    rgb(61, 92, 153),
  '125' rgb(102, 153, 255) rgba(102, 153, 255, 0.15) rgba(102, 153, 255, 0.08)
    rgb(61, 92, 153),
  '126' rgb(82, 122, 204) rgba(82, 122, 204, 0.15) rgba(82, 122, 204, 0.08)
    rgb(61, 92, 153),
  '127' rgb(148, 184, 255) rgba(148, 184, 255, 0.15) rgba(148, 184, 255, 0.08)
    rgb(61, 92, 153),
  '128' rgb(255, 235, 153) rgba(255, 235, 153, 0.15) rgba(255, 235, 153, 0.08)
    rgb(255, 204, 0),
  '129' rgb(25, 163, 163) rgba(25, 163, 163, 0.15) rgba(25, 163, 163, 0.08)
    rgb(0, 122, 122),
  '130' rgb(255, 148, 77) rgba(255, 148, 77, 0.15) rgba(255, 148, 77, 0.08)
    rgb(204, 82, 0),
  '131' rgb(128, 0, 0) rgba(128, 0, 0, 0.15) rgba(128, 0, 0, 0.08)
    rgb(141, 25, 25),
  '132' rgb(138, 92, 230) rgba(138, 92, 230, 0.15) rgba(138, 92, 230, 0.08)
    rgb(122, 82, 204),
  '133' rgb(0, 163, 82) rgba(0, 163, 82, 0.15) rgba(0, 163, 82, 0.08)
    rgb(74, 150, 74),
  '134' rgb(41, 163, 204) rgba(41, 163, 204, 0.15) rgba(41, 163, 204, 0.08)
    rgb(41, 163, 204),
  '135' rgb(230, 0, 0) rgba(230, 0, 0, 0.15) rgba(230, 0, 0, 0.08)
    rgb(178, 0, 0),
  '136' rgb(178, 178, 178) rgba(178, 178, 178, 0.15) rgba(178, 178, 178, 0.08)
    rgb(90, 90, 90),
  '137' rgb(255, 128, 128) rgba(255, 128, 128, 0.15) rgba(255, 128, 128, 0.08)
    rgb(178, 0, 0),
  '138' rgb(204, 204, 204) rgba(204, 204, 204, 0.15) rgba(204, 204, 204, 0.08)
    rgb(90, 90, 90),
  '139' rgb(173, 92, 51) rgba(173, 92, 51, 0.15) rgba(173, 92, 51, 0.08)
    rgb(138, 46, 0),
  '140' rgb(184, 112, 77) rgba(184, 112, 77, 0.15) rgba(184, 112, 77, 0.08)
    rgb(138, 46, 0),
  '141' rgb(230, 230, 230) rgba(230, 230, 230, 0.15) rgba(230, 230, 230, 0.08)
    rgb(90, 90, 90),
  '142' rgb(173, 133, 255) rgba(173, 133, 255, 0.15) rgba(173, 133, 255, 0.08)
    rgb(122, 82, 204),
  '143' rgb(153, 102, 255) rgba(153, 102, 255, 0.15) rgba(153, 102, 255, 0.08)
    rgb(122, 82, 204),
  '144' rgb(255, 224, 102) rgba(255, 224, 102, 0.15) rgba(255, 224, 102, 0.08)
    rgb(255, 204, 0),
  '145' rgb(122, 82, 204) rgba(122, 82, 204, 0.15) rgba(122, 82, 204, 0.08)
    rgb(122, 82, 204),
  '153' rgb(161, 28, 255) rgba(119, 115, 162, 0.15) rgba(119, 115, 162, 0.08)
    rgb(119, 115, 162),
  '188' rgb(34, 37, 43) rgba(34, 37, 43, 0.15) rgba(34, 37, 43, 0.08)
    rgb(34, 37, 43),
  '-1' rgb(255, 172, 39) rgba(255, 172, 39, 0.15) rgba(255, 172, 39, 0.08)
    rgb(255, 204, 39);

:root {
  @each $sport in $sportColorSet {
    --xx-sport-color-#{nth($sport, 1)}: #{nth($sport, 2)};
  }
}
