*[class*='flag_'] {
  background: url('url://#env.staticUrl#/regions/flags-small.png');
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
  position: relative;
  top: 1px;

  background-size: 1065px 1003px;
  width: 47px;
  height: 35px;
}

/* 47x35 */
.flag_ {
  background-position: -12px -12px;
}

.flag_0 {
  background-position: -12px -12px;
}

.flag_0-black {
  background-position: -83px -12px;
}

.flag_0-white {
  background-position: -154px -12px;
}

.flag_AFG {
  background-position: -225px -12px;
}

.flag_AIA {
  background-position: -296px -12px;
}

.flag_ALA {
  background-position: -367px -12px;
}

.flag_ALB {
  background-position: -438px -12px;
}

.flag_ALG {
  background-position: -509px -12px;
}

.flag_AND {
  background-position: -580px -12px;
}

.flag_ANG {
  background-position: -651px -12px;
}

.flag_ANT {
  background-position: -722px -12px;
}

.flag_ARG {
  background-position: -793px -12px;
}

.flag_ARM {
  background-position: -864px -12px;
}

.flag_ARU {
  background-position: -935px -12px;
}

.flag_ASA {
  background-position: -12px -71px;
}

.flag_AUS {
  background-position: -83px -71px;
}

.flag_AUT {
  background-position: -154px -71px;
}

.flag_AZE {
  background-position: -225px -71px;
}

.flag_BAH {
  background-position: -296px -71px;
}

.flag_BAN {
  background-position: -367px -71px;
}

.flag_BAR {
  background-position: -438px -71px;
}

.flag_BDI {
  background-position: -509px -71px;
}

.flag_BEL {
  background-position: -580px -71px;
}

.flag_BEN {
  background-position: -651px -71px;
}

.flag_BER {
  background-position: -722px -71px;
}

.flag_BHU {
  background-position: -793px -71px;
}

.flag_BIH {
  background-position: -864px -71px;
}

.flag_BIZ {
  background-position: -935px -71px;
}

.flag_BLR {
  background-position: -12px -130px;
}

.flag_BOL {
  background-position: -83px -130px;
}

.flag_BON {
  background-position: -154px -130px;
}

.flag_BOT {
  background-position: -225px -130px;
}

.flag_BRA {
  background-position: -296px -130px;
}

.flag_BRN {
  background-position: -367px -130px;
}

.flag_BRU {
  background-position: -438px -130px;
}

.flag_BSQ {
  background-position: -509px -130px;
}

.flag_BUL {
  background-position: -580px -130px;
}

.flag_BUR {
  background-position: -651px -130px;
}

.flag_CAF {
  background-position: -722px -130px;
}

.flag_CAM {
  background-position: -793px -130px;
}

.flag_CAN {
  background-position: -864px -130px;
}

.flag_CAT {
  background-position: -935px -130px;
}

.flag_CAY {
  background-position: -12px -189px;
}

.flag_CGO {
  background-position: -83px -189px;
}

.flag_CHA {
  background-position: -154px -189px;
}

.flag_CHI {
  background-position: -225px -189px;
}

.flag_CHN {
  background-position: -296px -189px;
}

.flag_CIV {
  background-position: -367px -189px;
}

.flag_CMR {
  background-position: -438px -189px;
}

.flag_COD {
  background-position: -509px -189px;
}

.flag_COK {
  background-position: -580px -189px;
}

.flag_COL {
  background-position: -651px -189px;
}

.flag_COM {
  background-position: -722px -189px;
}

.flag_CPV {
  background-position: -793px -189px;
}

.flag_CRC {
  background-position: -864px -189px;
}

.flag_CRO {
  background-position: -935px -189px;
}

.flag_CUB {
  background-position: -12px -248px;
}

.flag_CUW {
  background-position: -83px -248px;
}

.flag_CYP {
  background-position: -154px -248px;
}

.flag_CZE {
  background-position: -225px -248px;
}

.flag_DEN {
  background-position: -296px -248px;
}

.flag_DJI {
  background-position: -367px -248px;
}

.flag_DMA {
  background-position: -438px -248px;
}

.flag_DOM {
  background-position: -509px -248px;
}

.flag_ECU {
  background-position: -580px -248px;
}

.flag_EGY {
  background-position: -651px -248px;
}

.flag_ENG {
  background-position: -722px -248px;
}

.flag_ERI {
  background-position: -793px -248px;
}

.flag_ESA {
  background-position: -864px -248px;
}

.flag_ESH {
  background-position: -935px -248px;
}

.flag_ESP {
  background-position: -12px -307px;
}

.flag_EST {
  background-position: -83px -307px;
}

.flag_ETH {
  background-position: -154px -307px;
}

.flag_FAR {
  background-position: -225px -307px;
}

.flag_FIJ {
  background-position: -296px -307px;
}

.flag_FIN {
  background-position: -367px -307px;
}

.flag_FLK {
  background-position: -438px -307px;
}

.flag_FRA {
  background-position: -509px -307px;
}

.flag_FRO {
  background-position: -580px -307px;
}

.flag_FSM {
  background-position: -651px -307px;
}

.flag_GAB {
  background-position: -722px -307px;
}

.flag_GAM {
  background-position: -793px -307px;
}

.flag_GBR {
  background-position: -864px -307px;
}

.flag_GBS {
  background-position: -935px -307px;
}

.flag_GEO {
  background-position: -12px -366px;
}

.flag_GEQ {
  background-position: -83px -366px;
}

.flag_GER {
  background-position: -154px -366px;
}

.flag_GHA {
  background-position: -225px -366px;
}

.flag_GIB {
  background-position: -296px -366px;
}

.flag_GRE {
  background-position: -367px -366px;
}

.flag_GRL {
  background-position: -438px -366px;
}

.flag_GRN {
  background-position: -509px -366px;
}

.flag_GUA {
  background-position: -580px -366px;
}

.flag_GUD {
  background-position: -651px -366px;
}

.flag_GUF {
  background-position: -722px -366px;
}

.flag_GUI {
  background-position: -793px -366px;
}

.flag_GUM {
  background-position: -864px -366px;
}

.flag_GUY {
  background-position: -935px -366px;
}

.flag_HAI {
  background-position: -12px -425px;
}

.flag_HKG {
  background-position: -83px -425px;
}

.flag_HON {
  background-position: -154px -425px;
}

.flag_HUN {
  background-position: -225px -425px;
}

.flag_INA {
  background-position: -296px -425px;
}

.flag_IND {
  background-position: -367px -425px;
}

.flag_IRI {
  background-position: -438px -425px;
}

.flag_IRL {
  background-position: -509px -425px;
}

.flag_IRN {
  background-position: -580px -425px;
}

.flag_IRQ {
  background-position: -651px -425px;
}

.flag_ISL {
  background-position: -722px -425px;
}

.flag_ISR {
  background-position: -793px -425px;
}

.flag_ISV {
  background-position: -864px -425px;
}

.flag_ITA {
  background-position: -935px -425px;
}

.flag_IVB {
  background-position: -12px -484px;
}

.flag_JAM {
  background-position: -83px -484px;
}

.flag_JER {
  background-position: -154px -484px;
}

.flag_JOR {
  background-position: -225px -484px;
}

.flag_JPN {
  background-position: -296px -484px;
}

.flag_KAZ {
  background-position: -367px -484px;
}

.flag_KEN {
  background-position: -438px -484px;
}

.flag_KGZ {
  background-position: -509px -484px;
}

.flag_KIR {
  background-position: -580px -484px;
}

.flag_KOR {
  background-position: -651px -484px;
}

.flag_KOS {
  background-position: -722px -484px;
}

.flag_KSA {
  background-position: -793px -484px;
}

.flag_KUW {
  background-position: -864px -484px;
}

.flag_LAO {
  background-position: -935px -484px;
}

.flag_LAT {
  background-position: -12px -543px;
}

.flag_LBA {
  background-position: -83px -543px;
}

.flag_LBR {
  background-position: -154px -543px;
}

.flag_LCA {
  background-position: -225px -543px;
}

.flag_LES {
  background-position: -296px -543px;
}

.flag_LIB {
  background-position: -367px -543px;
}

.flag_LIE {
  background-position: -438px -543px;
}

.flag_LTU {
  background-position: -509px -543px;
}

.flag_LUX {
  background-position: -580px -543px;
}

.flag_MAC {
  background-position: -651px -543px;
}

.flag_MAD {
  background-position: -722px -543px;
}

.flag_MAF {
  background-position: -793px -543px;
}

.flag_MAR {
  background-position: -864px -543px;
}

.flag_MAW {
  background-position: -935px -543px;
}

.flag_MDA {
  background-position: -12px -602px;
}

.flag_MDV {
  background-position: -83px -602px;
}

.flag_MEX {
  background-position: -154px -602px;
}

.flag_MGL {
  background-position: -225px -602px;
}

.flag_MHL {
  background-position: -296px -602px;
}

.flag_MKD {
  background-position: -367px -602px;
}

.flag_MLI {
  background-position: -438px -602px;
}

.flag_MLT {
  background-position: -509px -602px;
}

.flag_MNE {
  background-position: -580px -602px;
}

.flag_MNP {
  background-position: -651px -602px;
}

.flag_MON {
  background-position: -722px -602px;
}

.flag_MOZ {
  background-position: -793px -602px;
}

.flag_MRI {
  background-position: -864px -602px;
}

.flag_MSR {
  background-position: -935px -602px;
}

.flag_MTN {
  background-position: -12px -661px;
}

.flag_MTQ {
  background-position: -83px -661px;
}

.flag_MYA {
  background-position: -154px -661px;
}

.flag_MYT {
  background-position: -225px -661px;
}

.flag_NAM {
  background-position: -296px -661px;
}

.flag_NCA {
  background-position: -367px -661px;
}

.flag_NCL {
  background-position: -438px -661px;
}

.flag_NED {
  background-position: -509px -661px;
}

.flag_NEP {
  background-position: -580px -661px;
}

.flag_NFK {
  background-position: -651px -661px;
}

.flag_NGR {
  background-position: -722px -661px;
}

.flag_NIG {
  background-position: -793px -661px;
}

.flag_NIR {
  background-position: -864px -661px;
}

.flag_NIU {
  background-position: -935px -661px;
}

.flag_NOR {
  background-position: -12px -720px;
}

.flag_NRU {
  background-position: -83px -720px;
}

.flag_NZL {
  background-position: -154px -720px;
}

.flag_OMA {
  background-position: -225px -720px;
}

.flag_PAK {
  background-position: -296px -720px;
}

.flag_PAN {
  background-position: -367px -720px;
}

.flag_PAR {
  background-position: -438px -720px;
}

.flag_PCN {
  background-position: -509px -720px;
}

.flag_PER {
  background-position: -580px -720px;
}

.flag_PHI {
  background-position: -651px -720px;
}

.flag_PLE {
  background-position: -722px -720px;
}

.flag_PLW {
  background-position: -793px -720px;
}

.flag_PNG {
  background-position: -864px -720px;
}

.flag_POL {
  background-position: -935px -720px;
}

.flag_POR {
  background-position: -12px -779px;
}

.flag_PRK {
  background-position: -83px -779px;
}

.flag_PUR {
  background-position: -154px -779px;
}

.flag_QAT {
  background-position: -225px -779px;
}

.flag_REU {
  background-position: -296px -779px;
}

.flag_ROU {
  background-position: -367px -779px;
}

.flag_RSA {
  background-position: -438px -779px;
}

.flag_RUS {
  background-position: -509px -779px;
}

.flag_RWA {
  background-position: -580px -779px;
}

.flag_SAM {
  background-position: -651px -779px;
}

.flag_SCO {
  background-position: -722px -779px;
}

.flag_SEN {
  background-position: -793px -779px;
}

.flag_SEY {
  background-position: -864px -779px;
}

.flag_SHN {
  background-position: -935px -779px;
}

.flag_SIN {
  background-position: -12px -838px;
}

.flag_SJM {
  background-position: -83px -838px;
}

.flag_SKN {
  background-position: -154px -838px;
}

.flag_SLE {
  background-position: -225px -838px;
}

.flag_SLO {
  background-position: -296px -838px;
}

.flag_SMR {
  background-position: -367px -838px;
}

.flag_SOL {
  background-position: -438px -838px;
}

.flag_SOM {
  background-position: -509px -838px;
}

.flag_SPM {
  background-position: -580px -838px;
}

.flag_SRB {
  background-position: -651px -838px;
}

.flag_SRI {
  background-position: -722px -838px;
}

.flag_SSD {
  background-position: -793px -838px;
}

.flag_STP {
  background-position: -864px -838px;
}

.flag_SUD {
  background-position: -935px -838px;
}

.flag_SUI {
  background-position: -12px -897px;
}

.flag_SUR {
  background-position: -83px -897px;
}

.flag_SVK {
  background-position: -154px -897px;
}

.flag_SWE {
  background-position: -225px -897px;
}

.flag_SYR {
  background-position: -296px -897px;
}

.flag_TAH {
  background-position: -367px -897px;
}

.flag_TAN {
  background-position: -438px -897px;
}

.flag_TCA {
  background-position: -509px -897px;
}

.flag_TGA {
  background-position: -580px -897px;
}

.flag_THA {
  background-position: -651px -897px;
}

.flag_TJK {
  background-position: -722px -897px;
}

.flag_TKL {
  background-position: -793px -897px;
}

.flag_TKM {
  background-position: -864px -897px;
}

.flag_TLS {
  background-position: -935px -897px;
}

.flag_TOG {
  background-position: -12px -956px;
}

.flag_TPE {
  background-position: -83px -956px;
}

.flag_TTO {
  background-position: -154px -956px;
}

.flag_TUN {
  background-position: -225px -956px;
}

.flag_TUR {
  background-position: -296px -956px;
}

.flag_TUV {
  background-position: -367px -956px;
}

.flag_UAE {
  background-position: -438px -956px;
}

.flag_UGA {
  background-position: -509px -956px;
}

.flag_UKR {
  background-position: -580px -956px;
}

.flag_URU {
  background-position: -651px -956px;
}

.flag_USA {
  background-position: -722px -956px;
}

.flag_UZB {
  background-position: -793px -956px;
}

.flag_VAN {
  background-position: -864px -956px;
}

.flag_VAT {
  background-position: -935px -956px;
}

.flag_VEN {
  background-position: -1006px -12px;
}

.flag_VIE {
  background-position: -1006px -71px;
}

.flag_VIN {
  background-position: -1006px -130px;
}

.flag_WAL {
  background-position: -1006px -189px;
}

.flag_WLF {
  background-position: -1006px -248px;
}

.flag_YEM {
  background-position: -1006px -307px;
}

.flag_ZAM {
  background-position: -1006px -366px;
}

.flag_ZIM {
  background-position: -1006px -425px;
}

.flag_ZNZ {
  background-position: -1006px -484px;
}

/* / 47x35 */

/* 22x16 */

*[class*='flag_'].size22 {
  background-size: 510px 476px;
  width: 22px;
  height: 16px;
}

.flag_0.size22 {
  background-position: -482px -258px;
}

.flag_0-black.size22 {
  background-position: -482px -286px;
}

.flag_0-white.size22 {
  background-position: -482px -314px;
}

.flag_AFG.size22 {
  background-position: -108px -6px;
}

.flag_AIA.size22 {
  background-position: -142px -6px;
}

.flag_ALA.size22 {
  background-position: -176px -6px;
}

.flag_ALB.size22 {
  background-position: -210px -6px;
}

.flag_ALG.size22 {
  background-position: -244px -6px;
}

.flag_AND.size22 {
  background-position: -278px -6px;
}

.flag_ANG.size22 {
  background-position: -312px -6px;
}

.flag_ANT.size22 {
  background-position: -346px -6px;
}

.flag_ARG.size22 {
  background-position: -380px -6px;
}

.flag_ARM.size22 {
  background-position: -414px -6px;
}

.flag_ARU.size22 {
  background-position: -448px -6px;
}

.flag_ASA.size22 {
  background-position: -6px -34px;
}

.flag_AUS.size22 {
  background-position: -40px -34px;
}

.flag_AUT.size22 {
  background-position: -74px -34px;
}

.flag_AZE.size22 {
  background-position: -108px -34px;
}

.flag_BAH.size22 {
  background-position: -142px -34px;
}

.flag_BAN.size22 {
  background-position: -176px -34px;
}

.flag_BAR.size22 {
  background-position: -210px -34px;
}

.flag_BDI.size22 {
  background-position: -244px -34px;
}

.flag_BEL.size22 {
  background-position: -278px -34px;
}

.flag_BEN.size22 {
  background-position: -312px -34px;
}

.flag_BER.size22 {
  background-position: -346px -34px;
}

.flag_BHU.size22 {
  background-position: -380px -34px;
}

.flag_BIH.size22 {
  background-position: -414px -34px;
}

.flag_BIZ.size22 {
  background-position: -448px -34px;
}

.flag_BLR.size22 {
  background-position: -6px -62px;
}

.flag_BOL.size22 {
  background-position: -40px -62px;
}

.flag_BON.size22 {
  background-position: -74px -62px;
}

.flag_BOT.size22 {
  background-position: -108px -62px;
}

.flag_BRA.size22 {
  background-position: -142px -62px;
}

.flag_BRN.size22 {
  background-position: -176px -62px;
}

.flag_BRU.size22 {
  background-position: -210px -62px;
}

.flag_BSQ.size22 {
  background-position: -244px -62px;
}

.flag_BUL.size22 {
  background-position: -278px -62px;
}

.flag_BUR.size22 {
  background-position: -312px -62px;
}

.flag_CAF.size22 {
  background-position: -346px -62px;
}

.flag_CAM.size22 {
  background-position: -380px -62px;
}

.flag_CAN.size22 {
  background-position: -414px -62px;
}

.flag_CAT.size22 {
  background-position: -448px -62px;
}

.flag_CAY.size22 {
  background-position: -6px -90px;
}

.flag_CGO.size22 {
  background-position: -40px -90px;
}

.flag_CHA.size22 {
  background-position: -74px -90px;
}

.flag_CHI.size22 {
  background-position: -108px -90px;
}

.flag_CHN.size22 {
  background-position: -142px -90px;
}

.flag_CIV.size22 {
  background-position: -176px -90px;
}

.flag_CMR.size22 {
  background-position: -210px -90px;
}

.flag_COD.size22 {
  background-position: -244px -90px;
}

.flag_COK.size22 {
  background-position: -278px -90px;
}

.flag_COL.size22 {
  background-position: -312px -90px;
}

.flag_COM.size22 {
  background-position: -346px -90px;
}

.flag_CPV.size22 {
  background-position: -380px -90px;
}

.flag_CRC.size22 {
  background-position: -414px -90px;
}

.flag_CRO.size22 {
  background-position: -448px -90px;
}

.flag_CUB.size22 {
  background-position: -6px -118px;
}

.flag_CUW.size22 {
  background-position: -40px -118px;
}

.flag_CYP.size22 {
  background-position: -74px -118px;
}

.flag_CZE.size22 {
  background-position: -108px -118px;
}

.flag_DEN.size22 {
  background-position: -142px -118px;
}

.flag_DJI.size22 {
  background-position: -176px -118px;
}

.flag_DMA.size22 {
  background-position: -210px -118px;
}

.flag_DOM.size22 {
  background-position: -244px -118px;
}

.flag_ECU.size22 {
  background-position: -278px -118px;
}

.flag_EGY.size22 {
  background-position: -312px -118px;
}

.flag_ENG.size22 {
  background-position: -346px -118px;
}

.flag_ERI.size22 {
  background-position: -380px -118px;
}

.flag_ESA.size22 {
  background-position: -414px -118px;
}

.flag_ESH.size22 {
  background-position: -448px -118px;
}

.flag_ESP.size22 {
  background-position: -6px -146px;
}

.flag_EST.size22 {
  background-position: -40px -146px;
}

.flag_ETH.size22 {
  background-position: -74px -146px;
}

.flag_FAR.size22 {
  background-position: -108px -146px;
}

.flag_FIJ.size22 {
  background-position: -142px -146px;
}

.flag_FIN.size22 {
  background-position: -176px -146px;
}

.flag_FLK.size22 {
  background-position: -210px -146px;
}

.flag_FRA.size22 {
  background-position: -244px -146px;
}

.flag_FRO.size22 {
  background-position: -278px -146px;
}

.flag_FSM.size22 {
  background-position: -312px -146px;
}

.flag_GAB.size22 {
  background-position: -346px -146px;
}

.flag_GAM.size22 {
  background-position: -380px -146px;
}

.flag_GBR.size22 {
  background-position: -414px -146px;
}

.flag_GBS.size22 {
  background-position: -448px -146px;
}

.flag_GEO.size22 {
  background-position: -6px -174px;
}

.flag_GEQ.size22 {
  background-position: -40px -174px;
}

.flag_GER.size22 {
  background-position: -74px -174px;
}

.flag_GHA.size22 {
  background-position: -108px -174px;
}

.flag_GIB.size22 {
  background-position: -142px -174px;
}

.flag_GRE.size22 {
  background-position: -176px -174px;
}

.flag_GRL.size22 {
  background-position: -210px -174px;
}

.flag_GRN.size22 {
  background-position: -244px -174px;
}

.flag_GUA.size22 {
  background-position: -278px -174px;
}

.flag_GUD.size22 {
  background-position: -312px -174px;
}

.flag_GUF.size22 {
  background-position: -346px -174px;
}

.flag_GUI.size22 {
  background-position: -380px -174px;
}

.flag_GUM.size22 {
  background-position: -414px -174px;
}

.flag_GUY.size22 {
  background-position: -448px -174px;
}

.flag_HAI.size22 {
  background-position: -6px -202px;
}

.flag_HKG.size22 {
  background-position: -40px -202px;
}

.flag_HON.size22 {
  background-position: -74px -202px;
}

.flag_HUN.size22 {
  background-position: -108px -202px;
}

.flag_INA.size22 {
  background-position: -142px -202px;
}

.flag_IND.size22 {
  background-position: -176px -202px;
}

.flag_IRI.size22 {
  background-position: -210px -202px;
}

.flag_IRL.size22 {
  background-position: -244px -202px;
}

.flag_IRN.size22 {
  background-position: -278px -202px;
}

.flag_IRQ.size22 {
  background-position: -312px -202px;
}

.flag_ISL.size22 {
  background-position: -346px -202px;
}

.flag_ISR.size22 {
  background-position: -380px -202px;
}

.flag_ISV.size22 {
  background-position: -414px -202px;
}

.flag_ITA.size22 {
  background-position: -448px -202px;
}

.flag_IVB.size22 {
  background-position: -6px -230px;
}

.flag_JAM.size22 {
  background-position: -40px -230px;
}

.flag_JER.size22 {
  background-position: -74px -230px;
}

.flag_JOR.size22 {
  background-position: -108px -230px;
}

.flag_JPN.size22 {
  background-position: -142px -230px;
}

.flag_KAZ.size22 {
  background-position: -176px -230px;
}

.flag_KEN.size22 {
  background-position: -210px -230px;
}

.flag_KGZ.size22 {
  background-position: -244px -230px;
}

.flag_KIR.size22 {
  background-position: -278px -230px;
}

.flag_KOR.size22 {
  background-position: -312px -230px;
}

.flag_KOS.size22 {
  background-position: -346px -230px;
}

.flag_KSA.size22 {
  background-position: -380px -230px;
}

.flag_KUW.size22 {
  background-position: -414px -230px;
}

.flag_LAO.size22 {
  background-position: -448px -230px;
}

.flag_LAT.size22 {
  background-position: -6px -258px;
}

.flag_LBA.size22 {
  background-position: -40px -258px;
}

.flag_LBR.size22 {
  background-position: -74px -258px;
}

.flag_LCA.size22 {
  background-position: -108px -258px;
}

.flag_LES.size22 {
  background-position: -142px -258px;
}

.flag_LIB.size22 {
  background-position: -176px -258px;
}

.flag_LIE.size22 {
  background-position: -210px -258px;
}

.flag_LTU.size22 {
  background-position: -244px -258px;
}

.flag_LUX.size22 {
  background-position: -278px -258px;
}

.flag_MAC.size22 {
  background-position: -312px -258px;
}

.flag_MAD.size22 {
  background-position: -346px -258px;
}

.flag_MAF.size22 {
  background-position: -380px -258px;
}

.flag_MAR.size22 {
  background-position: -414px -258px;
}

.flag_MAW.size22 {
  background-position: -448px -258px;
}

.flag_MDA.size22 {
  background-position: -6px -286px;
}

.flag_MDV.size22 {
  background-position: -40px -286px;
}

.flag_MEX.size22 {
  background-position: -74px -286px;
}

.flag_MGL.size22 {
  background-position: -108px -286px;
}

.flag_MHL.size22 {
  background-position: -142px -286px;
}

.flag_MKD.size22 {
  background-position: -176px -286px;
}

.flag_MLI.size22 {
  background-position: -210px -286px;
}

.flag_MLT.size22 {
  background-position: -244px -286px;
}

.flag_MNE.size22 {
  background-position: -278px -286px;
}

.flag_MNP.size22 {
  background-position: -312px -286px;
}

.flag_MON.size22 {
  background-position: -346px -286px;
}

.flag_MOZ.size22 {
  background-position: -380px -286px;
}

.flag_MRI.size22 {
  background-position: -414px -286px;
}

.flag_MSR.size22 {
  background-position: -448px -286px;
}

.flag_MTN.size22 {
  background-position: -6px -314px;
}

.flag_MTQ.size22 {
  background-position: -40px -314px;
}

.flag_MYA.size22 {
  background-position: -74px -314px;
}

.flag_MYT.size22 {
  background-position: -108px -314px;
}

.flag_NAM.size22 {
  background-position: -142px -314px;
}

.flag_NCA.size22 {
  background-position: -176px -314px;
}

.flag_NCL.size22 {
  background-position: -210px -314px;
}

.flag_NED.size22 {
  background-position: -244px -314px;
}

.flag_NEP.size22 {
  background-position: -278px -314px;
}

.flag_NFK.size22 {
  background-position: -312px -314px;
}

.flag_NGR.size22 {
  background-position: -346px -314px;
}

.flag_NIG.size22 {
  background-position: -380px -314px;
}

.flag_NIR.size22 {
  background-position: -414px -314px;
}

.flag_NIU.size22 {
  background-position: -448px -314px;
}

.flag_NOR.size22 {
  background-position: -6px -342px;
}

.flag_NRU.size22 {
  background-position: -40px -342px;
}

.flag_NZL.size22 {
  background-position: -74px -342px;
}

.flag_OMA.size22 {
  background-position: -108px -342px;
}

.flag_PAK.size22 {
  background-position: -142px -342px;
}

.flag_PAN.size22 {
  background-position: -176px -342px;
}

.flag_PAR.size22 {
  background-position: -210px -342px;
}

.flag_PCN.size22 {
  background-position: -244px -342px;
}

.flag_PER.size22 {
  background-position: -278px -342px;
}

.flag_PHI.size22 {
  background-position: -312px -342px;
}

.flag_PLE.size22 {
  background-position: -346px -342px;
}

.flag_PLW.size22 {
  background-position: -380px -342px;
}

.flag_PNG.size22 {
  background-position: -414px -342px;
}

.flag_POL.size22 {
  background-position: -448px -342px;
}

.flag_POR.size22 {
  background-position: -6px -370px;
}

.flag_PRK.size22 {
  background-position: -40px -370px;
}

.flag_PUR.size22 {
  background-position: -74px -370px;
}

.flag_QAT.size22 {
  background-position: -108px -370px;
}

.flag_REU.size22 {
  background-position: -142px -370px;
}

.flag_ROU.size22 {
  background-position: -176px -370px;
}

.flag_RSA.size22 {
  background-position: -210px -370px;
}

.flag_RUS.size22 {
  background-position: -244px -370px;
}

.flag_RWA.size22 {
  background-position: -278px -370px;
}

.flag_SAM.size22 {
  background-position: -312px -370px;
}

.flag_SCO.size22 {
  background-position: -346px -370px;
}

.flag_SEN.size22 {
  background-position: -380px -370px;
}

.flag_SEY.size22 {
  background-position: -414px -370px;
}

.flag_SHN.size22 {
  background-position: -448px -370px;
}

.flag_SIN.size22 {
  background-position: -6px -398px;
}

.flag_SJM.size22 {
  background-position: -40px -398px;
}

.flag_SKN.size22 {
  background-position: -74px -398px;
}

.flag_SLE.size22 {
  background-position: -108px -398px;
}

.flag_SLO.size22 {
  background-position: -142px -398px;
}

.flag_SMR.size22 {
  background-position: -176px -398px;
}

.flag_SOL.size22 {
  background-position: -210px -398px;
}

.flag_SOM.size22 {
  background-position: -244px -398px;
}

.flag_SPM.size22 {
  background-position: -278px -398px;
}

.flag_SRB.size22 {
  background-position: -312px -398px;
}

.flag_SRI.size22 {
  background-position: -346px -398px;
}

.flag_SSD.size22 {
  background-position: -380px -398px;
}

.flag_STP.size22 {
  background-position: -414px -398px;
}

.flag_SUD.size22 {
  background-position: -448px -398px;
}

.flag_SUI.size22 {
  background-position: -6px -426px;
}

.flag_SUR.size22 {
  background-position: -40px -426px;
}

.flag_SVK.size22 {
  background-position: -74px -426px;
}

.flag_SWE.size22 {
  background-position: -108px -426px;
}

.flag_SYR.size22 {
  background-position: -142px -426px;
}

.flag_TAH.size22 {
  background-position: -176px -426px;
}

.flag_TAN.size22 {
  background-position: -210px -426px;
}

.flag_TCA.size22 {
  background-position: -244px -426px;
}

.flag_TGA.size22 {
  background-position: -278px -426px;
}

.flag_THA.size22 {
  background-position: -312px -426px;
}

.flag_TJK.size22 {
  background-position: -346px -426px;
}

.flag_TKL.size22 {
  background-position: -380px -426px;
}

.flag_TKM.size22 {
  background-position: -414px -426px;
}

.flag_TLS.size22 {
  background-position: -448px -426px;
}

.flag_TOG.size22 {
  background-position: -6px -454px;
}

.flag_TPE.size22 {
  background-position: -40px -454px;
}

.flag_TTO.size22 {
  background-position: -74px -454px;
}

.flag_TUN.size22 {
  background-position: -108px -454px;
}

.flag_TUR.size22 {
  background-position: -142px -454px;
}

.flag_TUV.size22 {
  background-position: -176px -454px;
}

.flag_UAE.size22 {
  background-position: -210px -454px;
}

.flag_UGA.size22 {
  background-position: -244px -454px;
}

.flag_UKR.size22 {
  background-position: -278px -454px;
}

.flag_URU.size22 {
  background-position: -312px -454px;
}

.flag_USA.size22 {
  background-position: -346px -454px;
}

.flag_UZB.size22 {
  background-position: -380px -454px;
}

.flag_VAN.size22 {
  background-position: -414px -454px;
}

.flag_VAT.size22 {
  background-position: -448px -454px;
}

.flag_VEN.size22 {
  background-position: -482px -6px;
}

.flag_VIE.size22 {
  background-position: -482px -34px;
}

.flag_VIN.size22 {
  background-position: -482px -62px;
}

.flag_WAL.size22 {
  background-position: -482px -90px;
}

.flag_WLF.size22 {
  background-position: -482px -118px;
}

.flag_YEM.size22 {
  background-position: -482px -146px;
}

.flag_ZAM.size22 {
  background-position: -482px -174px;
}

.flag_ZIM.size22 {
  background-position: -482px -202px;
}

.flag_ZNZ.size22 {
  background-position: -482px -230px;
}

/* / 22x16 */

/* 16x12 */

*[class*='flag_'].size16 {
  background-size: 360px 340px;
  width: 16px;
  height: 12px;
}

.flag_0.size16 {
  background-position: -340px -184px;
}

.flag_0-black.size16 {
  background-position: -340px -204px;
}

.flag_0-white.size16 {
  background-position: -340px -224px;
}

.flag_AFG.size16 {
  background-position: -76px -4px;
}

.flag_AIA.size16 {
  background-position: -100px -4px;
}

.flag_ALA.size16 {
  background-position: -124px -4px;
}

.flag_ALB.size16 {
  background-position: -148px -4px;
}

.flag_ALG.size16 {
  background-position: -172px -4px;
}

.flag_AND.size16 {
  background-position: -196px -4px;
}

.flag_ANG.size16 {
  background-position: -220px -4px;
}

.flag_ANT.size16 {
  background-position: -244px -4px;
}

.flag_ARG.size16 {
  background-position: -268px -4px;
}

.flag_ARM.size16 {
  background-position: -292px -4px;
}

.flag_ARU.size16 {
  background-position: -316px -4px;
}

.flag_ASA.size16 {
  background-position: -4px -24px;
}

.flag_AUS.size16 {
  background-position: -28px -24px;
}

.flag_AUT.size16 {
  background-position: -52px -24px;
}

.flag_AZE.size16 {
  background-position: -76px -24px;
}

.flag_BAH.size16 {
  background-position: -100px -24px;
}

.flag_BAN.size16 {
  background-position: -124px -24px;
}

.flag_BAR.size16 {
  background-position: -148px -24px;
}

.flag_BDI.size16 {
  background-position: -172px -24px;
}

.flag_BEL.size16 {
  background-position: -196px -24px;
}

.flag_BEN.size16 {
  background-position: -220px -24px;
}

.flag_BER.size16 {
  background-position: -244px -24px;
}

.flag_BHU.size16 {
  background-position: -268px -24px;
}

.flag_BIH.size16 {
  background-position: -292px -24px;
}

.flag_BIZ.size16 {
  background-position: -316px -24px;
}

.flag_BLR.size16 {
  background-position: -4px -44px;
}

.flag_BOL.size16 {
  background-position: -28px -44px;
}

.flag_BON.size16 {
  background-position: -52px -44px;
}

.flag_BOT.size16 {
  background-position: -76px -44px;
}

.flag_BRA.size16 {
  background-position: -100px -44px;
}

.flag_BRN.size16 {
  background-position: -124px -44px;
}

.flag_BRU.size16 {
  background-position: -148px -44px;
}

.flag_BSQ.size16 {
  background-position: -172px -44px;
}

.flag_BUL.size16 {
  background-position: -196px -44px;
}

.flag_BUR.size16 {
  background-position: -220px -44px;
}

.flag_CAF.size16 {
  background-position: -244px -44px;
}

.flag_CAM.size16 {
  background-position: -268px -44px;
}

.flag_CAN.size16 {
  background-position: -292px -44px;
}

.flag_CAT.size16 {
  background-position: -316px -44px;
}

.flag_CAY.size16 {
  background-position: -4px -64px;
}

.flag_CGO.size16 {
  background-position: -28px -64px;
}

.flag_CHA.size16 {
  background-position: -52px -64px;
}

.flag_CHI.size16 {
  background-position: -76px -64px;
}

.flag_CHN.size16 {
  background-position: -100px -64px;
}

.flag_CIV.size16 {
  background-position: -124px -64px;
}

.flag_CMR.size16 {
  background-position: -148px -64px;
}

.flag_COD.size16 {
  background-position: -172px -64px;
}

.flag_COK.size16 {
  background-position: -196px -64px;
}

.flag_COL.size16 {
  background-position: -220px -64px;
}

.flag_COM.size16 {
  background-position: -244px -64px;
}

.flag_CPV.size16 {
  background-position: -268px -64px;
}

.flag_CRC.size16 {
  background-position: -292px -64px;
}

.flag_CRO.size16 {
  background-position: -316px -64px;
}

.flag_CUB.size16 {
  background-position: -4px -84px;
}

.flag_CUW.size16 {
  background-position: -28px -84px;
}

.flag_CYP.size16 {
  background-position: -52px -84px;
}

.flag_CZE.size16 {
  background-position: -76px -84px;
}

.flag_DEN.size16 {
  background-position: -100px -84px;
}

.flag_DJI.size16 {
  background-position: -124px -84px;
}

.flag_DMA.size16 {
  background-position: -148px -84px;
}

.flag_DOM.size16 {
  background-position: -172px -84px;
}

.flag_ECU.size16 {
  background-position: -196px -84px;
}

.flag_EGY.size16 {
  background-position: -220px -84px;
}

.flag_ENG.size16 {
  background-position: -244px -84px;
}

.flag_ERI.size16 {
  background-position: -268px -84px;
}

.flag_ESA.size16 {
  background-position: -292px -84px;
}

.flag_ESH.size16 {
  background-position: -316px -84px;
}

.flag_ESP.size16 {
  background-position: -4px -104px;
}

.flag_EST.size16 {
  background-position: -28px -104px;
}

.flag_ETH.size16 {
  background-position: -52px -104px;
}

.flag_FAR.size16 {
  background-position: -76px -104px;
}

.flag_FIJ.size16 {
  background-position: -100px -104px;
}

.flag_FIN.size16 {
  background-position: -124px -104px;
}

.flag_FLK.size16 {
  background-position: -148px -104px;
}

.flag_FRA.size16 {
  background-position: -172px -104px;
}

.flag_FRO.size16 {
  background-position: -196px -104px;
}

.flag_FSM.size16 {
  background-position: -220px -104px;
}

.flag_GAB.size16 {
  background-position: -244px -104px;
}

.flag_GAM.size16 {
  background-position: -268px -104px;
}

.flag_GBR.size16 {
  background-position: -292px -104px;
}

.flag_GBS.size16 {
  background-position: -316px -104px;
}

.flag_GEO.size16 {
  background-position: -4px -124px;
}

.flag_GEQ.size16 {
  background-position: -28px -124px;
}

.flag_GER.size16 {
  background-position: -52px -124px;
}

.flag_GHA.size16 {
  background-position: -76px -124px;
}

.flag_GIB.size16 {
  background-position: -100px -124px;
}

.flag_GRE.size16 {
  background-position: -124px -124px;
}

.flag_GRL.size16 {
  background-position: -148px -124px;
}

.flag_GRN.size16 {
  background-position: -172px -124px;
}

.flag_GUA.size16 {
  background-position: -196px -124px;
}

.flag_GUD.size16 {
  background-position: -220px -124px;
}

.flag_GUF.size16 {
  background-position: -244px -124px;
}

.flag_GUI.size16 {
  background-position: -268px -124px;
}

.flag_GUM.size16 {
  background-position: -292px -124px;
}

.flag_GUY.size16 {
  background-position: -316px -124px;
}

.flag_HAI.size16 {
  background-position: -4px -144px;
}

.flag_HKG.size16 {
  background-position: -28px -144px;
}

.flag_HON.size16 {
  background-position: -52px -144px;
}

.flag_HUN.size16 {
  background-position: -76px -144px;
}

.flag_INA.size16 {
  background-position: -100px -144px;
}

.flag_IND.size16 {
  background-position: -124px -144px;
}

.flag_IRI.size16 {
  background-position: -148px -144px;
}

.flag_IRL.size16 {
  background-position: -172px -144px;
}

.flag_IRN.size16 {
  background-position: -196px -144px;
}

.flag_IRQ.size16 {
  background-position: -220px -144px;
}

.flag_ISL.size16 {
  background-position: -244px -144px;
}

.flag_ISR.size16 {
  background-position: -268px -144px;
}

.flag_ISV.size16 {
  background-position: -292px -144px;
}

.flag_ITA.size16 {
  background-position: -316px -144px;
}

.flag_IVB.size16 {
  background-position: -4px -164px;
}

.flag_JAM.size16 {
  background-position: -28px -164px;
}

.flag_JER.size16 {
  background-position: -52px -164px;
}

.flag_JOR.size16 {
  background-position: -76px -164px;
}

.flag_JPN.size16 {
  background-position: -100px -164px;
}

.flag_KAZ.size16 {
  background-position: -124px -164px;
}

.flag_KEN.size16 {
  background-position: -148px -164px;
}

.flag_KGZ.size16 {
  background-position: -172px -164px;
}

.flag_KIR.size16 {
  background-position: -196px -164px;
}

.flag_KOR.size16 {
  background-position: -220px -164px;
}

.flag_KOS.size16 {
  background-position: -244px -164px;
}

.flag_KSA.size16 {
  background-position: -268px -164px;
}

.flag_KUW.size16 {
  background-position: -292px -164px;
}

.flag_LAO.size16 {
  background-position: -316px -164px;
}

.flag_LAT.size16 {
  background-position: -4px -184px;
}

.flag_LBA.size16 {
  background-position: -28px -184px;
}

.flag_LBR.size16 {
  background-position: -52px -184px;
}

.flag_LCA.size16 {
  background-position: -76px -184px;
}

.flag_LES.size16 {
  background-position: -100px -184px;
}

.flag_LIB.size16 {
  background-position: -124px -184px;
}

.flag_LIE.size16 {
  background-position: -148px -184px;
}

.flag_LTU.size16 {
  background-position: -172px -184px;
}

.flag_LUX.size16 {
  background-position: -196px -184px;
}

.flag_MAC.size16 {
  background-position: -220px -184px;
}

.flag_MAD.size16 {
  background-position: -244px -184px;
}

.flag_MAF.size16 {
  background-position: -268px -184px;
}

.flag_MAR.size16 {
  background-position: -292px -184px;
}

.flag_MAW.size16 {
  background-position: -316px -184px;
}

.flag_MDA.size16 {
  background-position: -4px -204px;
}

.flag_MDV.size16 {
  background-position: -28px -204px;
}

.flag_MEX.size16 {
  background-position: -52px -204px;
}

.flag_MGL.size16 {
  background-position: -76px -204px;
}

.flag_MHL.size16 {
  background-position: -100px -204px;
}

.flag_MKD.size16 {
  background-position: -124px -204px;
}

.flag_MLI.size16 {
  background-position: -148px -204px;
}

.flag_MLT.size16 {
  background-position: -172px -204px;
}

.flag_MNE.size16 {
  background-position: -196px -204px;
}

.flag_MNP.size16 {
  background-position: -220px -204px;
}

.flag_MON.size16 {
  background-position: -244px -204px;
}

.flag_MOZ.size16 {
  background-position: -268px -204px;
}

.flag_MRI.size16 {
  background-position: -292px -204px;
}

.flag_MSR.size16 {
  background-position: -316px -204px;
}

.flag_MTN.size16 {
  background-position: -4px -224px;
}

.flag_MTQ.size16 {
  background-position: -28px -224px;
}

.flag_MYA.size16 {
  background-position: -52px -224px;
}

.flag_MYT.size16 {
  background-position: -76px -224px;
}

.flag_NAM.size16 {
  background-position: -100px -224px;
}

.flag_NCA.size16 {
  background-position: -124px -224px;
}

.flag_NCL.size16 {
  background-position: -148px -224px;
}

.flag_NED.size16 {
  background-position: -172px -224px;
}

.flag_NEP.size16 {
  background-position: -196px -224px;
}

.flag_NFK.size16 {
  background-position: -220px -224px;
}

.flag_NGR.size16 {
  background-position: -244px -224px;
}

.flag_NIG.size16 {
  background-position: -268px -224px;
}

.flag_NIR.size16 {
  background-position: -292px -224px;
}

.flag_NIU.size16 {
  background-position: -316px -224px;
}

.flag_NOR.size16 {
  background-position: -4px -244px;
}

.flag_NRU.size16 {
  background-position: -28px -244px;
}

.flag_NZL.size16 {
  background-position: -52px -244px;
}

.flag_OMA.size16 {
  background-position: -76px -244px;
}

.flag_PAK.size16 {
  background-position: -100px -244px;
}

.flag_PAN.size16 {
  background-position: -124px -244px;
}

.flag_PAR.size16 {
  background-position: -148px -244px;
}

.flag_PCN.size16 {
  background-position: -172px -244px;
}

.flag_PER.size16 {
  background-position: -196px -244px;
}

.flag_PHI.size16 {
  background-position: -220px -244px;
}

.flag_PLE.size16 {
  background-position: -244px -244px;
}

.flag_PLW.size16 {
  background-position: -268px -244px;
}

.flag_PNG.size16 {
  background-position: -292px -244px;
}

.flag_POL.size16 {
  background-position: -316px -244px;
}

.flag_POR.size16 {
  background-position: -4px -264px;
}

.flag_PRK.size16 {
  background-position: -28px -264px;
}

.flag_PUR.size16 {
  background-position: -52px -264px;
}

.flag_QAT.size16 {
  background-position: -76px -264px;
}

.flag_REU.size16 {
  background-position: -100px -264px;
}

.flag_ROU.size16 {
  background-position: -124px -264px;
}

.flag_RSA.size16 {
  background-position: -148px -264px;
}

.flag_RUS.size16 {
  background-position: -172px -264px;
}

.flag_RWA.size16 {
  background-position: -196px -264px;
}

.flag_SAM.size16 {
  background-position: -220px -264px;
}

.flag_SCO.size16 {
  background-position: -244px -264px;
}

.flag_SEN.size16 {
  background-position: -268px -264px;
}

.flag_SEY.size16 {
  background-position: -292px -264px;
}

.flag_SHN.size16 {
  background-position: -316px -264px;
}

.flag_SIN.size16 {
  background-position: -4px -284px;
}

.flag_SJM.size16 {
  background-position: -28px -284px;
}

.flag_SKN.size16 {
  background-position: -52px -284px;
}

.flag_SLE.size16 {
  background-position: -76px -284px;
}

.flag_SLO.size16 {
  background-position: -100px -284px;
}

.flag_SMR.size16 {
  background-position: -124px -284px;
}

.flag_SOL.size16 {
  background-position: -148px -284px;
}

.flag_SOM.size16 {
  background-position: -172px -284px;
}

.flag_SPM.size16 {
  background-position: -196px -284px;
}

.flag_SRB.size16 {
  background-position: -220px -284px;
}

.flag_SRI.size16 {
  background-position: -244px -284px;
}

.flag_SSD.size16 {
  background-position: -268px -284px;
}

.flag_STP.size16 {
  background-position: -292px -284px;
}

.flag_SUD.size16 {
  background-position: -316px -284px;
}

.flag_SUI.size16 {
  background-position: -4px -304px;
}

.flag_SUR.size16 {
  background-position: -28px -304px;
}

.flag_SVK.size16 {
  background-position: -52px -304px;
}

.flag_SWE.size16 {
  background-position: -76px -304px;
}

.flag_SYR.size16 {
  background-position: -100px -304px;
}

.flag_TAH.size16 {
  background-position: -124px -304px;
}

.flag_TAN.size16 {
  background-position: -148px -304px;
}

.flag_TCA.size16 {
  background-position: -172px -304px;
}

.flag_TGA.size16 {
  background-position: -196px -304px;
}

.flag_THA.size16 {
  background-position: -220px -304px;
}

.flag_TJK.size16 {
  background-position: -244px -304px;
}

.flag_TKL.size16 {
  background-position: -268px -304px;
}

.flag_TKM.size16 {
  background-position: -292px -304px;
}

.flag_TLS.size16 {
  background-position: -316px -304px;
}

.flag_TOG.size16 {
  background-position: -4px -324px;
}

.flag_TPE.size16 {
  background-position: -28px -324px;
}

.flag_TTO.size16 {
  background-position: -52px -324px;
}

.flag_TUN.size16 {
  background-position: -76px -324px;
}

.flag_TUR.size16 {
  background-position: -100px -324px;
}

.flag_TUV.size16 {
  background-position: -124px -324px;
}

.flag_UAE.size16 {
  background-position: -148px -324px;
}

.flag_UGA.size16 {
  background-position: -172px -324px;
}

.flag_UKR.size16 {
  background-position: -196px -324px;
}

.flag_URU.size16 {
  background-position: -220px -324px;
}

.flag_USA.size16 {
  background-position: -244px -324px;
}

.flag_UZB.size16 {
  background-position: -268px -324px;
}

.flag_VAN.size16 {
  background-position: -292px -324px;
}

.flag_VAT.size16 {
  background-position: -316px -324px;
}

.flag_VEN.size16 {
  background-position: -340px -4px;
}

.flag_VIE.size16 {
  background-position: -340px -24px;
}

.flag_VIN.size16 {
  background-position: -340px -44px;
}

.flag_WAL.size16 {
  background-position: -340px -64px;
}

.flag_WLF.size16 {
  background-position: -340px -84px;
}

.flag_YEM.size16 {
  background-position: -340px -104px;
}

.flag_ZAM.size16 {
  background-position: -340px -124px;
}

.flag_ZIM.size16 {
  background-position: -340px -144px;
}

.flag_ZNZ.size16 {
  background-position: -340px -164px;
}

/* / 16x12 */

/* 11x8 */

*[class*='flag_'].size11 {
  background-size: 255px 238px;
  width: 11px;
  height: 8px;
}

.flag_0.size11 {
  background-position: -241px -129px;
}

.flag_0-black.size11 {
  background-position: -241px -143px;
}

.flag_0-white.size11 {
  background-position: -241px -157px;
}

.flag_AFG.size11 {
  background-position: -54px -3px;
}

.flag_AIA.size11 {
  background-position: -71px -3px;
}

.flag_ALA.size11 {
  background-position: -88px -3px;
}

.flag_ALB.size11 {
  background-position: -105px -3px;
}

.flag_ALG.size11 {
  background-position: -122px -3px;
}

.flag_AND.size11 {
  background-position: -139px -3px;
}

.flag_ANG.size11 {
  background-position: -156px -3px;
}

.flag_ANT.size11 {
  background-position: -173px -3px;
}

.flag_ARG.size11 {
  background-position: -190px -3px;
}

.flag_ARM.size11 {
  background-position: -207px -3px;
}

.flag_ARU.size11 {
  background-position: -224px -3px;
}

.flag_ASA.size11 {
  background-position: -3px -17px;
}

.flag_AUS.size11 {
  background-position: -20px -17px;
}

.flag_AUT.size11 {
  background-position: -37px -17px;
}

.flag_AZE.size11 {
  background-position: -54px -17px;
}

.flag_BAH.size11 {
  background-position: -71px -17px;
}

.flag_BAN.size11 {
  background-position: -88px -17px;
}

.flag_BAR.size11 {
  background-position: -105px -17px;
}

.flag_BDI.size11 {
  background-position: -122px -17px;
}

.flag_BEL.size11 {
  background-position: -139px -17px;
}

.flag_BEN.size11 {
  background-position: -156px -17px;
}

.flag_BER.size11 {
  background-position: -173px -17px;
}

.flag_BHU.size11 {
  background-position: -190px -17px;
}

.flag_BIH.size11 {
  background-position: -207px -17px;
}

.flag_BIZ.size11 {
  background-position: -224px -17px;
}

.flag_BLR.size11 {
  background-position: -3px -31px;
}

.flag_BOL.size11 {
  background-position: -20px -31px;
}

.flag_BON.size11 {
  background-position: -37px -31px;
}

.flag_BOT.size11 {
  background-position: -54px -31px;
}

.flag_BRA.size11 {
  background-position: -71px -31px;
}

.flag_BRN.size11 {
  background-position: -88px -31px;
}

.flag_BRU.size11 {
  background-position: -105px -31px;
}

.flag_BSQ.size11 {
  background-position: -122px -31px;
}

.flag_BUL.size11 {
  background-position: -139px -31px;
}

.flag_BUR.size11 {
  background-position: -156px -31px;
}

.flag_CAF.size11 {
  background-position: -173px -31px;
}

.flag_CAM.size11 {
  background-position: -190px -31px;
}

.flag_CAN.size11 {
  background-position: -207px -31px;
}

.flag_CAT.size11 {
  background-position: -224px -31px;
}

.flag_CAY.size11 {
  background-position: -3px -45px;
}

.flag_CGO.size11 {
  background-position: -20px -45px;
}

.flag_CHA.size11 {
  background-position: -37px -45px;
}

.flag_CHI.size11 {
  background-position: -54px -45px;
}

.flag_CHN.size11 {
  background-position: -71px -45px;
}

.flag_CIV.size11 {
  background-position: -88px -45px;
}

.flag_CMR.size11 {
  background-position: -105px -45px;
}

.flag_COD.size11 {
  background-position: -122px -45px;
}

.flag_COK.size11 {
  background-position: -139px -45px;
}

.flag_COL.size11 {
  background-position: -156px -45px;
}

.flag_COM.size11 {
  background-position: -173px -45px;
}

.flag_CPV.size11 {
  background-position: -190px -45px;
}

.flag_CRC.size11 {
  background-position: -207px -45px;
}

.flag_CRO.size11 {
  background-position: -224px -45px;
}

.flag_CUB.size11 {
  background-position: -3px -59px;
}

.flag_CUW.size11 {
  background-position: -20px -59px;
}

.flag_CYP.size11 {
  background-position: -37px -59px;
}

.flag_CZE.size11 {
  background-position: -54px -59px;
}

.flag_DEN.size11 {
  background-position: -71px -59px;
}

.flag_DJI.size11 {
  background-position: -88px -59px;
}

.flag_DMA.size11 {
  background-position: -105px -59px;
}

.flag_DOM.size11 {
  background-position: -122px -59px;
}

.flag_ECU.size11 {
  background-position: -139px -59px;
}

.flag_EGY.size11 {
  background-position: -156px -59px;
}

.flag_ENG.size11 {
  background-position: -173px -59px;
}

.flag_ERI.size11 {
  background-position: -190px -59px;
}

.flag_ESA.size11 {
  background-position: -207px -59px;
}

.flag_ESH.size11 {
  background-position: -224px -59px;
}

.flag_ESP.size11 {
  background-position: -3px -73px;
}

.flag_EST.size11 {
  background-position: -20px -73px;
}

.flag_ETH.size11 {
  background-position: -37px -73px;
}

.flag_FAR.size11 {
  background-position: -54px -73px;
}

.flag_FIJ.size11 {
  background-position: -71px -73px;
}

.flag_FIN.size11 {
  background-position: -88px -73px;
}

.flag_FLK.size11 {
  background-position: -105px -73px;
}

.flag_FRA.size11 {
  background-position: -122px -73px;
}

.flag_FRO.size11 {
  background-position: -139px -73px;
}

.flag_FSM.size11 {
  background-position: -156px -73px;
}

.flag_GAB.size11 {
  background-position: -173px -73px;
}

.flag_GAM.size11 {
  background-position: -190px -73px;
}

.flag_GBR.size11 {
  background-position: -207px -73px;
}

.flag_GBS.size11 {
  background-position: -224px -73px;
}

.flag_GEO.size11 {
  background-position: -3px -87px;
}

.flag_GEQ.size11 {
  background-position: -20px -87px;
}

.flag_GER.size11 {
  background-position: -37px -87px;
}

.flag_GHA.size11 {
  background-position: -54px -87px;
}

.flag_GIB.size11 {
  background-position: -71px -87px;
}

.flag_GRE.size11 {
  background-position: -88px -87px;
}

.flag_GRL.size11 {
  background-position: -105px -87px;
}

.flag_GRN.size11 {
  background-position: -122px -87px;
}

.flag_GUA.size11 {
  background-position: -139px -87px;
}

.flag_GUD.size11 {
  background-position: -156px -87px;
}

.flag_GUF.size11 {
  background-position: -173px -87px;
}

.flag_GUI.size11 {
  background-position: -190px -87px;
}

.flag_GUM.size11 {
  background-position: -207px -87px;
}

.flag_GUY.size11 {
  background-position: -224px -87px;
}

.flag_HAI.size11 {
  background-position: -3px -101px;
}

.flag_HKG.size11 {
  background-position: -20px -101px;
}

.flag_HON.size11 {
  background-position: -37px -101px;
}

.flag_HUN.size11 {
  background-position: -54px -101px;
}

.flag_INA.size11 {
  background-position: -71px -101px;
}

.flag_IND.size11 {
  background-position: -88px -101px;
}

.flag_IRI.size11 {
  background-position: -105px -101px;
}

.flag_IRL.size11 {
  background-position: -122px -101px;
}

.flag_IRN.size11 {
  background-position: -139px -101px;
}

.flag_IRQ.size11 {
  background-position: -156px -101px;
}

.flag_ISL.size11 {
  background-position: -173px -101px;
}

.flag_ISR.size11 {
  background-position: -190px -101px;
}

.flag_ISV.size11 {
  background-position: -207px -101px;
}

.flag_ITA.size11 {
  background-position: -224px -101px;
}

.flag_IVB.size11 {
  background-position: -3px -115px;
}

.flag_JAM.size11 {
  background-position: -20px -115px;
}

.flag_JER.size11 {
  background-position: -37px -115px;
}

.flag_JOR.size11 {
  background-position: -54px -115px;
}

.flag_JPN.size11 {
  background-position: -71px -115px;
}

.flag_KAZ.size11 {
  background-position: -88px -115px;
}

.flag_KEN.size11 {
  background-position: -105px -115px;
}

.flag_KGZ.size11 {
  background-position: -122px -115px;
}

.flag_KIR.size11 {
  background-position: -139px -115px;
}

.flag_KOR.size11 {
  background-position: -156px -115px;
}

.flag_KOS.size11 {
  background-position: -173px -115px;
}

.flag_KSA.size11 {
  background-position: -190px -115px;
}

.flag_KUW.size11 {
  background-position: -207px -115px;
}

.flag_LAO.size11 {
  background-position: -224px -115px;
}

.flag_LAT.size11 {
  background-position: -3px -129px;
}

.flag_LBA.size11 {
  background-position: -20px -129px;
}

.flag_LBR.size11 {
  background-position: -37px -129px;
}

.flag_LCA.size11 {
  background-position: -54px -129px;
}

.flag_LES.size11 {
  background-position: -71px -129px;
}

.flag_LIB.size11 {
  background-position: -88px -129px;
}

.flag_LIE.size11 {
  background-position: -105px -129px;
}

.flag_LTU.size11 {
  background-position: -122px -129px;
}

.flag_LUX.size11 {
  background-position: -139px -129px;
}

.flag_MAC.size11 {
  background-position: -156px -129px;
}

.flag_MAD.size11 {
  background-position: -173px -129px;
}

.flag_MAF.size11 {
  background-position: -190px -129px;
}

.flag_MAR.size11 {
  background-position: -207px -129px;
}

.flag_MAW.size11 {
  background-position: -224px -129px;
}

.flag_MDA.size11 {
  background-position: -3px -143px;
}

.flag_MDV.size11 {
  background-position: -20px -143px;
}

.flag_MEX.size11 {
  background-position: -37px -143px;
}

.flag_MGL.size11 {
  background-position: -54px -143px;
}

.flag_MHL.size11 {
  background-position: -71px -143px;
}

.flag_MKD.size11 {
  background-position: -88px -143px;
}

.flag_MLI.size11 {
  background-position: -105px -143px;
}

.flag_MLT.size11 {
  background-position: -122px -143px;
}

.flag_MNE.size11 {
  background-position: -139px -143px;
}

.flag_MNP.size11 {
  background-position: -156px -143px;
}

.flag_MON.size11 {
  background-position: -173px -143px;
}

.flag_MOZ.size11 {
  background-position: -190px -143px;
}

.flag_MRI.size11 {
  background-position: -207px -143px;
}

.flag_MSR.size11 {
  background-position: -224px -143px;
}

.flag_MTN.size11 {
  background-position: -3px -157px;
}

.flag_MTQ.size11 {
  background-position: -20px -157px;
}

.flag_MYA.size11 {
  background-position: -37px -157px;
}

.flag_MYT.size11 {
  background-position: -54px -157px;
}

.flag_NAM.size11 {
  background-position: -71px -157px;
}

.flag_NCA.size11 {
  background-position: -88px -157px;
}

.flag_NCL.size11 {
  background-position: -105px -157px;
}

.flag_NED.size11 {
  background-position: -122px -157px;
}

.flag_NEP.size11 {
  background-position: -139px -157px;
}

.flag_NFK.size11 {
  background-position: -156px -157px;
}

.flag_NGR.size11 {
  background-position: -173px -157px;
}

.flag_NIG.size11 {
  background-position: -190px -157px;
}

.flag_NIR.size11 {
  background-position: -207px -157px;
}

.flag_NIU.size11 {
  background-position: -224px -157px;
}

.flag_NOR.size11 {
  background-position: -3px -171px;
}

.flag_NRU.size11 {
  background-position: -20px -171px;
}

.flag_NZL.size11 {
  background-position: -37px -171px;
}

.flag_OMA.size11 {
  background-position: -54px -171px;
}

.flag_PAK.size11 {
  background-position: -71px -171px;
}

.flag_PAN.size11 {
  background-position: -88px -171px;
}

.flag_PAR.size11 {
  background-position: -105px -171px;
}

.flag_PCN.size11 {
  background-position: -122px -171px;
}

.flag_PER.size11 {
  background-position: -139px -171px;
}

.flag_PHI.size11 {
  background-position: -156px -171px;
}

.flag_PLE.size11 {
  background-position: -173px -171px;
}

.flag_PLW.size11 {
  background-position: -190px -171px;
}

.flag_PNG.size11 {
  background-position: -207px -171px;
}

.flag_POL.size11 {
  background-position: -224px -171px;
}

.flag_POR.size11 {
  background-position: -3px -185px;
}

.flag_PRK.size11 {
  background-position: -20px -185px;
}

.flag_PUR.size11 {
  background-position: -37px -185px;
}

.flag_QAT.size11 {
  background-position: -54px -185px;
}

.flag_REU.size11 {
  background-position: -71px -185px;
}

.flag_ROU.size11 {
  background-position: -88px -185px;
}

.flag_RSA.size11 {
  background-position: -105px -185px;
}

.flag_RUS.size11 {
  background-position: -122px -185px;
}

.flag_RWA.size11 {
  background-position: -139px -185px;
}

.flag_SAM.size11 {
  background-position: -156px -185px;
}

.flag_SCO.size11 {
  background-position: -173px -185px;
}

.flag_SEN.size11 {
  background-position: -190px -185px;
}

.flag_SEY.size11 {
  background-position: -207px -185px;
}

.flag_SHN.size11 {
  background-position: -224px -185px;
}

.flag_SIN.size11 {
  background-position: -3px -199px;
}

.flag_SJM.size11 {
  background-position: -20px -199px;
}

.flag_SKN.size11 {
  background-position: -37px -199px;
}

.flag_SLE.size11 {
  background-position: -54px -199px;
}

.flag_SLO.size11 {
  background-position: -71px -199px;
}

.flag_SMR.size11 {
  background-position: -88px -199px;
}

.flag_SOL.size11 {
  background-position: -105px -199px;
}

.flag_SOM.size11 {
  background-position: -122px -199px;
}

.flag_SPM.size11 {
  background-position: -139px -199px;
}

.flag_SRB.size11 {
  background-position: -156px -199px;
}

.flag_SRI.size11 {
  background-position: -173px -199px;
}

.flag_SSD.size11 {
  background-position: -190px -199px;
}

.flag_STP.size11 {
  background-position: -207px -199px;
}

.flag_SUD.size11 {
  background-position: -224px -199px;
}

.flag_SUI.size11 {
  background-position: -3px -213px;
}

.flag_SUR.size11 {
  background-position: -20px -213px;
}

.flag_SVK.size11 {
  background-position: -37px -213px;
}

.flag_SWE.size11 {
  background-position: -54px -213px;
}

.flag_SYR.size11 {
  background-position: -71px -213px;
}

.flag_TAH.size11 {
  background-position: -88px -213px;
}

.flag_TAN.size11 {
  background-position: -105px -213px;
}

.flag_TCA.size11 {
  background-position: -122px -213px;
}

.flag_TGA.size11 {
  background-position: -139px -213px;
}

.flag_THA.size11 {
  background-position: -156px -213px;
}

.flag_TJK.size11 {
  background-position: -173px -213px;
}

.flag_TKL.size11 {
  background-position: -190px -213px;
}

.flag_TKM.size11 {
  background-position: -207px -213px;
}

.flag_TLS.size11 {
  background-position: -224px -213px;
}

.flag_TOG.size11 {
  background-position: -3px -227px;
}

.flag_TPE.size11 {
  background-position: -20px -227px;
}

.flag_TTO.size11 {
  background-position: -37px -227px;
}

.flag_TUN.size11 {
  background-position: -54px -227px;
}

.flag_TUR.size11 {
  background-position: -71px -227px;
}

.flag_TUV.size11 {
  background-position: -88px -227px;
}

.flag_UAE.size11 {
  background-position: -105px -227px;
}

.flag_UGA.size11 {
  background-position: -122px -227px;
}

.flag_UKR.size11 {
  background-position: -139px -227px;
}

.flag_URU.size11 {
  background-position: -156px -227px;
}

.flag_USA.size11 {
  background-position: -173px -227px;
}

.flag_UZB.size11 {
  background-position: -190px -227px;
}

.flag_VAN.size11 {
  background-position: -207px -227px;
}

.flag_VAT.size11 {
  background-position: -224px -227px;
}

.flag_VEN.size11 {
  background-position: -241px -3px;
}

.flag_VIE.size11 {
  background-position: -241px -17px;
}

.flag_VIN.size11 {
  background-position: -241px -31px;
}

.flag_WAL.size11 {
  background-position: -241px -45px;
}

.flag_WLF.size11 {
  background-position: -241px -59px;
}

.flag_YEM.size11 {
  background-position: -241px -73px;
}

.flag_ZAM.size11 {
  background-position: -241px -87px;
}

.flag_ZIM.size11 {
  background-position: -241px -101px;
}

.flag_ZNZ.size11 {
  background-position: -241px -115px;
}

/* / 11x8 */
