$accent-50: #e8f5e9;
$accent-100: #d0ebd3;
$accent-200: #a1d8a7;
$accent-300: #73c47c;
$accent-400: #44b150;
$accent-500: #159d24;
$accent-600: #117e1d;
$accent-700: #0d5e16;
$accent-800: #083f0e;
$accent-900: #041f07;

:root {
  --primary-25: #eeeef0;
  --primary-50: #e6e7e8;
  --primary-75: #dbdcde;
  --primary-100: #c2c3c6;
  --primary-200: #999ca0;
  --primary-250: #82878e;
  --primary-300: #707479;
  --primary-350: #62676e;
  --primary-400: #51565d;
  --primary-450: #41464e;
  --primary-500: #323840;
  --primary-600: #2d323a;
  --primary-700: #262b32;
  --primary-800: #1f242a;
  --primary-900: #13171c;

  --accent-50: $accent-50
  --accent-100: $accent-100
  --accent-200: $accent-200
  --accent-300: $accent-300
  --accent-400: $accent-400
  --accent-500: $accent-500
  --accent-600: $accent-600
  --accent-700: $accent-700
  --accent-800: $accent-800
  --accent-900: $accent-900

  --blue-500: #21a0f1;

  --yellow-500: #e2c241;

  --green-500: #72cd21;
}
