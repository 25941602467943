@font-face {
  font-family: 'sports';
  src: url('sports/sports.eot?85895816');
  src: url('sports/sports.eot?85895816#iefix') format('embedded-opentype'),
    url('sports/sports.ttf?85895816') format('truetype'),
    url('sports/sports.woff?85895816') format('woff'),
    url('sports/sports.svg?85895816#sports') format('svg');
  font-weight: normal;
  font-style: normal;
  /* font-size: 12px; */
}

[class^='sport-icon-'],
[class*=' sport-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sports' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='sport-icon-']:before,
[class*=' sport-icon-']:before {
  content: '\e900';
}

.sport-icon-0:before {
  content: '\e900';
}
.sport-icon-48:before {
  content: '\e901';
}
.sport-icon-49:before {
  content: '\e902';
}
.sport-icon-50:before {
  content: '\e903';
}
.sport-icon-51:before {
  content: '\e904';
}
.sport-icon-52:before {
  content: '\e905';
}
.sport-icon-53:before {
  content: '\e906';
}
.sport-icon-54:before {
  content: '\e907';
}
.sport-icon-55:before {
  content: '\e908';
}
.sport-icon-58:before {
  content: '\e909';
}
.sport-icon-60:before {
  content: '\e90a';
}
.sport-icon-61:before {
  content: '\e90b';
}
.sport-icon-62:before {
  content: '\e90c';
}
.sport-icon-63:before {
  content: '\e90d';
}
.sport-icon-64:before {
  content: '\e90e';
}
.sport-icon-65:before {
  content: '\e90f';
}
.sport-icon-67:before {
  content: '\e910';
}
.sport-icon-68:before {
  content: '\e911';
}
.sport-icon-69:before {
  content: '\e912';
}
.sport-icon-70:before {
  content: '\e913';
}
.sport-icon-71:before {
  content: '\e914';
}
.sport-icon-72:before {
  content: '\e915';
}
.sport-icon-73:before {
  content: '\e916';
}
.sport-icon-74:before {
  content: '\e917';
}
.sport-icon-75:before {
  content: '\e918';
}
.sport-icon-76:before {
  content: '\e919';
}
.sport-icon-77:before {
  content: '\e91a';
}
.sport-icon-78:before {
  content: '\e91b';
}
.sport-icon-80:before {
  content: '\e91c';
}
.sport-icon-81:before {
  content: '\e91d';
}
.sport-icon-82:before {
  content: '\e91e';
}
.sport-icon-83:before {
  content: '\e91f';
}
.sport-icon-86:before {
  content: '\e920';
}
.sport-icon-87:before {
  content: '\e921';
}
.sport-icon-88:before {
  content: '\e922';
}
.sport-icon-89:before {
  content: '\e923';
}
.sport-icon-90:before {
  content: '\e924';
}
.sport-icon-92:before {
  content: '\e925';
}
.sport-icon-93:before {
  content: '\e926';
}
.sport-icon-94:before {
  content: '\e927';
}
.sport-icon-95:before {
  content: '\e928';
}
.sport-icon-96:before {
  content: '\e929';
}
.sport-icon-97:before {
  content: '\e92a';
}
.sport-icon-98:before {
  content: '\e92b';
}
.sport-icon-99:before {
  content: '\e92c';
}
.sport-icon-100:before {
  content: '\e92d';
}
.sport-icon-101:before {
  content: '\e92e';
}
.sport-icon-102:before {
  content: '\e92f';
}
.sport-icon-103:before {
  content: '\e930';
}
.sport-icon-104:before {
  content: '\e931';
}
.sport-icon-105:before {
  content: '\e932';
}
.sport-icon-106:before {
  content: '\e933';
}
.sport-icon-107:before {
  content: '\e934';
}
.sport-icon-108:before {
  content: '\e935';
}
.sport-icon-109:before {
  content: '\e936';
}
.sport-icon-110:before {
  content: '\e937';
}
.sport-icon-114:before {
  content: '\e938';
}
.sport-icon-115:before {
  content: '\e939';
}
.sport-icon-116:before {
  content: '\e93a';
}
.sport-icon-117:before {
  content: '\e93b';
}
.sport-icon-118:before {
  content: '\e93c';
}
.sport-icon-119:before {
  content: '\e93d';
}
.sport-icon-120:before {
  content: '\e93e';
}
.sport-icon-121:before {
  content: '\e93f';
}
.sport-icon-122:before {
  content: '\e940';
}
.sport-icon-123:before {
  content: '\e941';
}
.sport-icon-124:before {
  content: '\e942';
}
.sport-icon-125:before {
  content: '\e943';
}
.sport-icon-126:before {
  content: '\e944';
}
.sport-icon-130:before {
  content: '\e945';
}
.sport-icon-131:before {
  content: '\e946';
}
.sport-icon-143:before {
  content: '\e947';
}
.sport-icon-151:before {
  content: '\e948';
}
.sport-icon-152:before {
  content: '\e949';
}
.sport-icon-153-region_1235:before {
  content: '\e94a';
}
.sport-icon-153-region_1236:before {
  content: '\e94b';
}
.sport-icon-153-region_1237:before {
  content: '\e94c';
}
.sport-icon-153-region_1238:before {
  content: '\e94d';
}
.sport-icon-153-region_1239:before {
  content: '\e94e';
}
.sport-icon-153-region_2139:before {
  content: '\e94f';
}
.sport-icon-153-region_2286:before {
  content: '\e950';
}
.sport-icon-153:before {
  content: '\e951';
}
.sport-icon-158:before {
  content: '\e952';
}
.sport-icon-161:before {
  content: '\e953';
}
.sport-icon-166:before {
  content: '\e954';
}
.sport-icon-167:before {
  content: '\e955';
}
.sport-icon-171:before {
  content: '\e956';
}
.sport-icon-178:before {
  content: '\e957';
}
.sport-icon-183:before {
  content: '\e958';
}
.sport-icon-188:before {
  content: '\e959';
}
.sport-icon-194:before {
  content: '\e95a';
}
.sport-icon-195:before {
  content: '\e95b';
}
.sport-icon-196:before {
  content: '\e95c';
}
.sport-icon-197:before {
  content: '\e95d';
}
.sport-icon-198:before {
  content: '\e95e';
}
.sport-icon-199:before {
  content: '\e95f';
}
