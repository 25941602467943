.label_bold {
  font-weight: 700;
}
.label_italic {
  font-style: italic;
}
.label_line_throug {
  text-decoration: line-through;
}
.label_monospace {
  font-family: monospace;
}
.label_link {
  text-decoration: underline;
  cursor: pointer;
}
.info_contains-link {
  font-size: 12px;
  color: var(--ion-color-primary-light);
  h1 {
    margin: 0px !important;
  }
  .label_link {
    color: var(--ion-color-secondary);
    text-decoration: none;
    font-size: 13px;
    font-weight: 400;
    line-height: 27px;
  }
  .label_link:before {
    content: '\A';
    white-space: pre;
  }
}
