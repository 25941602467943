/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* Import sweiper scss */
@import 'swiper/scss';
@import 'swiper/scss/autoplay';
@import 'swiper/scss/pagination';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/colors';
@import './styles/material-design/material-design';
@import './styles/reset';

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');
@import './styles//fonts/icons.scss';
@import './styles/fonts/sport-icons.css';

@font-face {
  font-family: 'proximanovaexcn-medium';

  src: url('https://static.doxxbet.sk/doxxbetPortal/assets/fonts/proximanova/proximanovaexcn-medium-webfont.woff2')
      format('woff2'),
    url('https://static.doxxbet.sk/doxxbetPortal/assets/fonts/proximanova/proximanovaexcn-medium-webfont.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'proximanovaexcn-blackit';
  src: url('https://static.doxxbet.sk/doxxbetPortal/assets/fonts/proximanova/proximanovaexcn-blackit-webfont.woff2')
      format('woff2'),
    url('https://static.doxxbet.sk/doxxbetPortal/assets/fonts/proximanova/proximanovaexcn-blackit-webfont.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/* Import custom styles  */
@import './styles/app';

html {
  min-height: 100vh;
}

.loading-modal {
  --background: rgba(50, 56, 64, 0.9);
}

.contacts-popover {
  --min-width: 300px;
  doxx-contacts {
    background: var(--ion-color-light);
  }
}

@mixin alert($color) {
  .alert-wrapper {
    background: transparent;
    border-radius: 5px;
  }

  .alert-message {
    background: white;
    border-radius: 5px;
    padding-top: 40px;
    max-height: 80%;

    h2 {
      color: var(--ion-color-primary);
      font-weight: bold;
      font-size: 18px;
      margin: 10px 0 20px;
    }

    p {
      color: #7e848a;
    }
    a {
      text-decoration: none;
      color: red;
    }

    ion-fab {
      top: 0px;
      width: 100%;
      left: 0px;

      ion-fab-button {
        --background-hover: white;
        z-index: 999;

        &::part(native) {
          background: white;
          color: var($color);
          cursor: default;
        }

        margin: 0 auto;
      }
    }

    ion-icon {
      font-size: 18px;
      font-weight: bold;
      contain: none;
    }
  }
  .alert-button-group {
    background: white;
    margin-top: -4px;
    .alert-button {
      border-top: 0;
      border-color: var(--ion-color-light);
      color: var(--ion-color-danger);
    }
  }
  .alert-head {
    height: 25px;
  }

  .primary-btn {
    background: red;
    color: white !important;
    font-weight: bold;
  }
  .expand-btn {
    width: 100%;
    border-radius: 5px;
    height: 50px;
    margin: 5px 10px;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .bt {
    border-top: 1px solid #f7f7f7 !important;
  }
  .b {
    font-weight: bold;
  }
}

.error-alert {
  @include alert(--ion-color-danger);
}

.success-alert {
  @include alert(--ion-color-success);
}

.info-alert {
  @include alert(--ion-color-blue);
}

.info-alert-stamp {
  @include alert(--ion-color-danger);
}

.info-alert-blue {
  @include alert(--ion-color-blue);
}

.casino-play-alert {
  @include alert(--ion-color-danger);


  .alert-radio-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;

    border: none;
    background: white;
    font-family: 'Roboto Condensed';
    font-size: 16px;
    font-weight: 700;

      .button-main {
        border-radius: 4px;
        background: #F30D31;
        .alert-radio-label {
          color: #FFFFFF;
        }
      }

      .button-secondary {
        border: 1px solid #CCC;
        border-radius: 4px;
        .alert-radio-label {
          color: #7e848a;
        }
      }


      .alert-radio-icon {
        display: none;
      }
      .alert-radio-label {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    
  }

  .alert-message{
    padding-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .alert-button-group {
    margin-top: 0px;
    
    .alert-button {
      height: 80px;
      background: #f7f7f7;
      .alert-button-inner {
        font-weight: 500;
      }
    }
  }
}

.popover-overflow-visible {
  .popover-content {
    overflow: visible;
  }
}

.popover-wrapper {
  background: transparent;
  border-radius: 5px;
  .popover-content {
    position: static;
    width: 300px;
    margin: 0 auto;
    background: transparent;
    box-shadow: none;
  }
}

ion-radio::part(container) {
  border-color: #999999;
}

ion-radio::part(mark) {
  background-color: var(--ion-color-secondary);
}

ion-toolbar {
  ion-title {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    padding: 0 5px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      color: var(--ion-color-secondary-contrast);
    }
    span {
      font-weight: 400;
      color: #989b9f;
    }
  }
}

.buyback-modal,
.autobuyback-modal,
.odds-modal,
.register-log-in-modal {
  --width: 90vw;
  --overflow: visible;
  --border-radius: 5px;
  --max-width: 500px;
  --height: auto;
  z-index: 9;
  --background: #fff;
}

.odds-modal {
  --background: #f1f2f2;
}

ion-segment-button {
  button {
    pointer-events: none;
  }
}

.capitalize {
  text-transform: capitalize;
}
.capitalize-first {
  &::first-letter {
    text-transform: capitalize;
  }
}

.fixed-button-bottom-wrapper {
  position: fixed;
  z-index: 100;
  bottom: 0px;
  width: 100%;
  background: #ffffff;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  max-height: 100px;
  color: #777d83;
}

.live-small-icon,
.virtual-small-icon {
  font-size: 12px;
  letter-spacing: -2.8px;
}

.live-small-icon {
  color: var(--ion-color-secondary);
}

.virtual-small-icon {
  color: var(--color-purple1);
}

.modal-fullscreen {
  .modal-wrapper {
    width: 100vw;
    height: 100vh;
  }
}

.input-livesearch-modal {
  --background: #0000008f;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
