.text-balanced {
  color: var(--color-balanced);
}
.text-assertive {
  color: var(--color-assertive);
}
.text-pend {
  color: var(--color-pend);
}
.text-secondary {
  color: var(--ion-color-secondary);
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
