// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #202020;
  // --ion-color-primary-rgb: 32,32,32;
  // --ion-color-primary-contrast: #ffffff;
  // --ion-color-primary-contrast-rgb: 255,255,255;
  // --ion-color-primary-shade: #1c1c1c;
  // --ion-color-primary-tint: #363636;

  // --ion-color-primary: #323840;
  --ion-color-primary-rgb: 50, 56, 64;
  --ion-color-primary-contrast: #999ca0;
  --ion-color-primary-contrast-freebet: #bdc1c4;
  --ion-color-primary-contrast-rgb: rgba(255, 255, 255, 0.5);
  --ion-color-primary-shade: #2c3138;
  --ion-color-primary-tint: #4e545a;
  --ion-color-primary-light: #4e545a;

  --ion-color-secondary: #159d24;
  --ion-color-secondary-new: #159d24;
  --ion-color-secondary-rgb: 244, 0, 52;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #159d24;
  --ion-color-secondary-tint: #159d24;

  --ion-color-tertiary: #dedede;
  --ion-color-tertiary-rgb: 222, 222, 222;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #c3c3c3;
  --ion-color-tertiary-tint: #e1e1e1;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #e2c241;
  --ion-color-warning-rgb: 226, 194, 65;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c7ab39;
  --ion-color-warning-tint: #e5c854;

  --ion-color-danger: #f30d31;
  --ion-color-danger-rgb: 243, 13, 49;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d60b2b;
  --ion-color-danger-tint: #f42546;

  --ion-color-dark: #4f555c;
  --ion-color-dark-rgb: 79, 85, 92;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #464b51;
  --ion-color-dark-tint: #61666c;

  --ion-color-medium: #62686f;
  --ion-color-medium-rgb: 98, 104, 111;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #565c62;
  --ion-color-medium-tint: #72777d;

  --ion-color-white: #fff;
  --ion-color-light: #f2f2f2;
  //--ion-color-light: #fff;
  --ion-color-light-rgb: 242, 242, 242;
  --ion-color-light-contrast-old: #7e848a;
  --ion-color-light-contrast: #323840;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d5d5d5;
  --ion-color-light-tint: #f3f3f3;

  --color-black: #5d5d5d;
  --color-purple1: #a11cff;
  --color-purple2: #ff00ff;
  --color-purple3: #35015b;
  --color-purple4: #5f07a0;

  --color-success-background: #eaf8de;

  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: var(--ion-color-primary-contrast);
  --ion-tab-bar-color: var(--ion-color-primary-contrast);
  --ion-tab-bar-color-selected: var(--ion-color-secondary);
  --ion-tab-bar-background: var(--ion-color-primary);
  --ion-background-color: var(--ion-color-light);

  --ion-color-blue: #2aa4ef;
  --ion-color-blue-light: #2ea3ee;
  --ion-color-green-rate: #76ce27;
  --overall-rate-color: #f40034;

  --text-color-black: #323840;

  --ion-color-grey-1: #bec1c4;

  --ion-color-whitered: white;
  --ion-color-whitered-rgb: 242, 242, 242;
  --ion-color-whitered-contrast: #f30d31;
  --ion-color-whitered-contrast-rgb: 0, 0, 0;
  --ion-color-whitered-shade: #d5d5d5;
  --ion-color-whitered-tint: #f3f3f3;

  font-family: 'Roboto Condensed', sans-serif;
  --ion-font-family: 'Roboto Condensed', sans-serif;

  --color-balanced: #85d537;
  --color-assertive: #f51848;
  --color-pend: #2aa4ef;
  --color-buyback: #72cd21;

  --ion-casino-color-10: #f40034;
  --ion-casino-color-20: #0da3f3;
  --ion-casino-color-40: #f30d31;
  --ion-casino-color-50: #0ec98f;
  --ion-casino-color-60: #969ca2;
  --ion-casino-color--10: #c026cc;
  --ion-casino-color--20: #85cb49;
  --ion-casino-color-30: #1556a2;
  --ion-casino-color-101: #ebac00;
  --ion-casino-color-102: #ebac00;
  --ion-casino-color-103: #ee4023;
  --ion-casino-color-104: #ff7700;
  --ion-casino-color-1: #1556a2;
  --ion-casino-color-2: #f30d31;

  #ticketMenu {
    --width: 100%;
    --min-width: 100%;
  }

  .combinations-detail-fullscreen {
    --width: 100%;
    --height: 100%;
  }

  .range-knob {
    border: 14px solid white;
    top: -7px;
    --knob-box-shadow: 3px 2px 9px #dedede;
    --knob-background: red;
  }

  ion-badge.rate {
    text-align: center;
    font-size: 12px;
    margin-right: 0;
    color: #ffffff;
    border-radius: 2px;
    //padding: 4px 7px 4px 7px;
    background-color: var(--ion-color-medium-tint);
    &.overall {
      background-color: var(--overall-rate-color);
    }
    &.index {
      padding: 3px 6px 3px 6px;
      font-weight: 100;
      &.y {
        background-color: var(--ticket-status-bg-sold);
        color: var(--ion-color-green-rate);
      }
      &.r {
        background-color: var(--accent-75);
        color: #f40034;
      }
      &.b {
        background-color: #e8f5fe;
        color: var(--ion-color-blue-light);
      }
    }
  }

  .bold {
    font-weight: bold;
  }

  .dark-bold {
    color: #000;
    font-weight: bold;
  }

  .is-anchor {
    cursor: pointer;
  }

  --virtual-purple-color: #ad3aff;

  --ticket-status-detail-labels-color: #7e848a;
  --ticket-status-detail-values-color: #9b9b9b;

  --ticket-status-color-won: #85d537;
  --ticket-status-color-lost: #f51848;
  --ticket-status-color-pend: #2aa4ef;
  --ticket-status-color-expired: #e2c241;
  --ticket-status-color-canceled: #bec1c4;
  --ticket-status-color-norisk: #0dcac8;
  --ticket-status-color-refused: #e2c241;
  --ticket-status-color-buyback: #85d537;
  --ticket-status-color-compensation: #85d537;
  --ticket-status-color-await: #bec1c4;
  --ticket-status-color-edited: #bec1c4;

  --ticket-status-background-color-won: #f5fbef;
  --ticket-status-background-color-lost: #feecef;
  --ticket-status-background-color-pend: #e9f5fe;
  --ticket-status-background-color-stored: #f7effb;
  --ticket-status-background-color-canceled: #f7f2e8;
  --ticket-status-background-color-norisk: #ecfbfb;
  --ticket-status-background-color-refused: #fafafa;
  --ticket-status-background-color-buyback: #f5fbef;
  --ticket-status-background-color-compensation: #f5fbef;
  --ticket-status-background-color-await: #f5fbef;
  --ticket-status-background-color-expired: #f7f2e8;
  --ticket-status-background-color-edited: #fafafa;

  #ticketHistory,
  #ticketEvents,
  #ticketDetailContent,
  #ticketList,
  #landing-my-tickets,
  .content-contains-ticket-statuses {
    .won {
      color: var(--ticket-status-color-won);
    }
    .lost {
      color: var(--ticket-status-color-lost);
    }
    .pend {
      color: var(--ticket-status-color-pend);
    }
    .canceled,
    .refused {
      color: var(--ticket-status-color-canceled);
    }
    .norisk {
      color: var(--ticket-status-color-norisk);
    }
    .buyback {
      color: var(--ticket-status-color-buyback);
    }
    .compensation {
      color: var(--ticket-status-color-compensation);
    }
    .await {
      color: var(--ticket-status-color-await);
    }

    .border-won {
      border-color: var(--ticket-status-color-won);
    }
    .border-lost {
      border-color: var(--ticket-status-color-lost);
    }
    .border-pend {
      border-color: var(--ticket-status-color-pend);
    }
    .border-canceled,
    .border-refused {
      border-color: var(--ticket-status-color-canceled);
    }
    .border-norisk {
      border-color: var(--ticket-status-color-norisk);
    }
    .border-buyback {
      border-color: var(--ticket-status-color-buyback);
    }
    .border-compensation {
      border-color: var(--ticket-status-color-compensation);
    }
    .border-await {
      border-color: var(--ticket-status-color-refused);
    }

    .background-won {
      background-color: var(--ticket-status-background-color-won) !important;
    }
    .background-lost {
      background-color: var(--ticket-status-background-color-lost) !important;
    }
    .background-pend {
      background-color: var(--ticket-status-background-color-pend) !important;
    }
    .background-canceled,
    .background-refused {
      background-color: var(
        --ticket-status-background-color-canceled
      ) !important;
    }
    .background-stored {
      background-color: var(--ticket-status-background-color-stored) !important;
    }
    .background-norisk {
      background-color: var(--ticket-status-background-color-norisk) !important;
    }
    .background-buyback {
      background-color: var(
        --ticket-status-background-color-buyback
      ) !important;
    }
    .background-compensation {
      background-color: var(
        --ticket-status-background-color-compensation
      ) !important;
    }
    .background-await {
      background-color: var(
        --ticket-status-background-color-refused
      ) !important;
    }
    .background-expired {
      background-color: var(
        --ticket-status-background-color-expired
      ) !important;
    }
  }

  // ticket history
  /* base colors */

  --slate-12: #f5f6f7;
  --slate-25: #eeeef0;
  --slate-50: #e6e7e8;
  --slate-75: #dbdcde;
  --slate-100: #c2c3c6;
  --slate-200: #999ca0;
  --slate-250: #82878e;
  --slate-300: #707479;
  --slate-350: #62676e;
  --slate-400: #51565d;
  --slate-450: #41464e;
  --slate-500: #323840;
  --slate-600: #2d323a;
  --slate-700: #262b32;
  --slate-800: #1f242a;
  --slate-900: #13171c;
  //--state-900: #202020;

  --accent-50: #fffefe;
  --accent-75: #feebef;
  --accent-100: #ffb2c2;
  --accent-200: #ff7a96;
  --accent-300: #ff325e;
  --accent-400: #ff1446;
  --accent-500: #f40034;
  --accent-600: #d5002d;
  --accent-700: #b70027;
  --accent-800: #980020;
  --accent-900: #7a001a;

  --blue-500: #21a0f1;

  --yellow-500: #e2c241;

  --green-50: #f5fbef;
  --green-500: #72cd21;
  --green-600: #58c120;
  --green-700: #46b10d;
  --green-800: #3fa508;
  /* tickets colors */

  --match-status-gradient-lost: linear-gradient(
    180deg,
    rgba(244, 121, 0, 1) 0%,
    rgba(171, 0, 36, 1) 100%
  );
  --match-status-gradient-win: linear-gradient(
    180deg,
    rgba(234, 250, 130, 1) 0%,
    rgba(69, 176, 12, 1) 100%
  );

  --ticket-status-bg-waiting: var(--slate-12);
  --ticket-status-color-waiting: var(--slate-500);
  --ticket-status-icon-color-waiting: var(--slate-100);

  --ticket-status-bg-await: var(--slate-12);
  --ticket-status-color-await: var(--slate-500);
  --ticket-status-icon-color-await: var(--slate-100);

  --ticket-status-bg-approved: #e8f5fe;
  --ticket-status-color-approved: var(--blue-500);
  --ticket-status-icon-color-approved: var(--blue-500);

  --ticket-status-bg-win: #f5fbef;
  --ticket-status-color-win: var(--green-500);
  --ticket-status-icon-color-win: var(--green-500);

  --ticket-status-bg-lost: #feebef;
  --ticket-status-color-lost: var(--accent-500);
  --ticket-status-icon-color-lost: var(--accent-500);

  --ticket-status-bg-canceled: var(--slate-12);
  --ticket-status-color-canceled: var((--ion-color-grey-1));
  --ticket-status-icon-color-canceled: var(--slate-100);

  --ticket-status-bg-rejected: #f7f2e8;
  --ticket-status-color-rejected: var(--slate-500);
  --ticket-status-icon-color-rejected: var(--yellow-500);

  --ticket-status-bg-expired: #f7f2e8;
  --ticket-status-color-expired: var(--slate-500);
  --ticket-status-icon-color-expired: var(--yellow-500);

  --ticket-status-bg-compensation: #f5fbef;
  --ticket-status-color-compensation: var(--green-500);
  --ticket-status-icon-color-compensation: var(--green-500);

  --ticket-status-bg-sold: #f5fbef;
  --ticket-status-color-sold: var(--green-500);
  --ticket-status-icon-color-sold: var(--green-500);

  --ticket-status-bg-refused: #f7f2e8;
  --ticket-status-color-refused: var(--yellow-500);
  --ticket-status-icon-color-refused: var(--yellow-500);
}

.ion-color-whitered {
  --ion-color-base: var(--ion-color-whitered);
  --ion-color-base-rgb: var(--ion-color-whitered-rgb);
  --ion-color-contrast: var(--ion-color-whitered-contrast);
  --ion-color-contrast-rgb: var(--ion-color-whitered-contrast-rgb);
  --ion-color-shade: var(--ion-color-whitered-shade);
  --ion-color-tint: var(--ion-color-whitered-tint);
}

ion-card {
  --background: var(--ion-color-dark-contrast);
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #428cff;
//     --ion-color-primary-rgb: 66, 140, 255;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255, 255, 255;
//     --ion-color-primary-shade: #3a7be0;
//     --ion-color-primary-tint: #5598ff;

//     --ion-color-secondary: #50c8ff;
//     --ion-color-secondary-rgb: 80, 200, 255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255, 255, 255;
//     --ion-color-secondary-shade: #46b0e0;
//     --ion-color-secondary-tint: #62ceff;

//     --ion-color-tertiary: #6a64ff;
//     --ion-color-tertiary-rgb: 106, 100, 255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//     --ion-color-tertiary-shade: #5d58e0;
//     --ion-color-tertiary-tint: #7974ff;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47, 223, 117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0, 0, 0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255, 213, 52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0, 0, 0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255, 73, 97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255, 255, 255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244, 245, 248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0, 0, 0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152, 154, 162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0, 0, 0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34, 36, 40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255, 255, 255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0, 0, 0;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-toolbar-background: #0d0d0d;

//     --ion-item-background: #000000;
//   }

//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18, 18, 18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;
//   }
// }

.filter-modal-component {
  .popover-viewport {
    max-height: 100vh;
  }
  .popover-content {
    position: static;
    width: 300px;
    margin: 0 auto;
  }

  .filter-radio {
    white-space: normal;
  }
}
