.responsible-gaming-modal {
  --width: 90vw;
  --height: 80vh;
  --border-radius: 10px;
  --background: var(--ion-color-medium);
  --overflow: visible;
  z-index: 999999999999 !important;
  ion-backdrop {
    --backdrop-opacity: 0.8;
    backdrop-filter: blur(4px);
  }
}
