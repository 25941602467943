.analysis {
  .analysis-body {
    text-align: left;
    .path {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, min-content));
      grid-auto-flow: column;
      gap: 5px;
      ion-badge {
        color: #fff;
        * {
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0;
          margin: 0;
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 700;
        }
      }
    }
    .analysis-date {
      margin: 5px 0;
      font-size: 14px;
      font-weight: bold;
      color: var(--ion-color-medium-tint);
    }
    .analysis-title-wrapper {
      display: flex;
      .analysis-title {
        font-size: 18px;
        margin: 5px 0;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1;
        width: 0;
      }
    }
    .analysis-banner {
      padding: 10px 0;
    }
    .analysis-article {
      color: var(--ion-color-medium-tint);
      font-size: 17px;
      overflow: hidden;
      p {
        margin: 0;
      }
    }
  }
  .analysis-footer {
    .analysis-stats-container {
      display: grid;
      grid-auto-flow: column;
      .analysis-stats {
        border-top: 1px solid var(--ion-color-light-shade);
        border-right: 1px solid var(--ion-color-light-shade);
        &.skeleton {
          padding: 12px 15px 8px;
          font-size: 12px;
          display: flex;
          justify-content: space-between;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
    .analysis-stats-progress-container {
      .analysis-stats-title {
        font-size: 17px;
        font-weight: 700;
        margin-bottom: 25px;
      }
      .analysis-stats-progress {
        display: grid;
        grid-template-columns: 30px 0fr auto 20px;
        align-items: center;
        white-space: nowrap;
        gap: 10px;
        .team-name {
          margin: 0;
          font-size: 14px;
          font-weight: bold;
        }
        .progressbar-wrapper {
          height: 3px;
          background-color: var(--ion-color-light-shade);
          width: 100%;
          position: relative;
          .progressbar {
            height: 100%;
            background: linear-gradient(
              90deg,
              rgba(2, 0, 36, 1) 0%,
              rgba(0, 255, 0, 1) 0%,
              rgba(61, 194, 0, 1) 19%,
              rgba(217, 140, 0, 1) 73%,
              rgba(255, 0, 0, 1) 100%
            );
            position: absolute;
          }
        }
      }
    }
  }
}

.analyses-filter {
  .filter-active {
    background: var(--ion-color-light-shade);
  }
}
