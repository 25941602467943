@use '@angular/material' as mat;
@import '../colors';
@include mat.core();

$primary: (
  50: var(--primary-50),
  100: var(--primary-100),
  200: var(--primary-200),
  300: var(--primary-300),
  400: var(--primary-400),
  500: var(--primary-500),
  600: var(--primary-600),
  700: var(--primary-700),
  800: var(--primary-800),
  900: var(--primary-900),
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff
  )
);
$accent: (
  50: $accent-50,
  100: $accent-100,
  200: $accent-200,
  300: $accent-300,
  400: $accent-400,
  500: $accent-500,
  600: $accent-600,
  700: $accent-700,
  800: $accent-800,
  900: $accent-900,
  contrast: (
    50: #13171c,
    100: #13171c,
    200: #13171c,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff
  )
);
$xx-ui-primary: mat.define-palette($primary);
$xx-ui-accent: mat.define-palette($accent);

// The warn palette is optional (defaults to red).
$xx-ui-warn: mat.define-palette($accent);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $xx-ui-primary,
      accent: $xx-ui-accent,
      warn: $xx-ui-warn
    )
  )
);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $xx-ui-primary,
      accent: $xx-ui-accent,
      warn: $xx-ui-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

// Apply the light theme by default
@include mat.all-component-themes($light-theme);

// Apply the dark theme only when the `.dark` CSS class is applied to body
body.dark {
  @include mat.all-component-themes($dark-theme);
}

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
  $font-family: 'Roboto Condensed, sans-serif'
);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include mat.core($custom-typography);

.mat-dialog-container {
  height: fit-content !important;
  @apply p-0 mx-5 bg-transparent overflow-visible #{!important};
}

.notifications-bottom-sheet .mat-bottom-sheet-container {
  padding: 0;
  overflow: visible;
  background: transparent;
  box-shadow: none;
}
