@import 'sport-colors';
@import 'sprite';
@import 'typography';
@import 'bonuses';
@import 'analysis';
@import 'actuality';
@import 'responsible-gaming';
@import 'parsed-labels';
@import 'modals';

* :focus {
  outline: none;
}

.icon-expand-collapse {
  font-size: 18px;
  display: inline-block;
}
