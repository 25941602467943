.actuality {
  text-align: left;
  padding: 15px 20px;
  .actuality-header {
    display: flex;
    padding-bottom: 10px;
    .icon {
      max-height: 50px;
      margin-right: 15px;
      align-self: center;
    }

    .actuality-title-wrapper {
      display: flex;
      flex-direction: column;
      width: 0;
      flex-grow: 1;
      .actuality-date {
        margin: 5px 0;
        font-size: 14px;
        font-weight: bold;
        color: var(--ion-color-medium-tint);
      }
      .actuality-title {
        font-size: 18px;
        margin: 5px 0;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1;
      }
    }
  }
  .actuality-body {
    .actuality-article {
      color: var(--ion-color-medium-tint);
      font-size: 14px;
      line-height: 16px;
      height: 48px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      p {
        margin: 0;
      }
    }
  }
}
